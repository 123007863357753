import serviceEmployeeApi from "../../../../services/api/ServiceEmployee";

export const createServiceEmployee = async (
  employeeId: string,
  serviceStoreId: string
) => {
  try {
    const res = await serviceEmployeeApi.createServiceEmployee(
      employeeId,
      serviceStoreId
    );

    console.log(`Service-employee created: ${res.id}`);
  } catch (error) {
    console.warn(`Error creating a service-employee: `, error);
  }
};

export const validateServiceStorePrices = (
  fixedPrice,
  mondayPrice,
  tuesdayPrice,
  wednesdayPrice,
  thursdayPrice,
  fridayPrice,
  saturdayPrice,
  sundayPrice,
  dynamicPriceAllowed
) => {
  if (!dynamicPriceAllowed && fixedPrice > 0) return true;

  if (
    dynamicPriceAllowed &&
    sundayPrice > 0 &&
    mondayPrice > 0 &&
    tuesdayPrice > 0 &&
    wednesdayPrice > 0 &&
    thursdayPrice > 0 &&
    fridayPrice > 0 &&
    saturdayPrice > 0
  )
    return true;

  return false;
};
