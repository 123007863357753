import PriceInput from "../../../../components/general/PriceInput";
import { WEEKDAY_LABELS } from "../workingTimeConstants";

interface DynamicPriceInputProps {
  weekdayIndex: number;
  value: number;
  setValue: (value: number) => void;
  submittedOnce: boolean;
}

const DynamicPriceInput = (props: DynamicPriceInputProps) => {
  return (
    <PriceInput
      size="small"
      label={`${WEEKDAY_LABELS[props.weekdayIndex]} *`}
      placeholder="Ex.: R$ 50,00"
      value={props.value}
      onChange={(e) => props.setValue(Number(e.target.value))}
      error={props.value < 1 && props.submittedOnce}
      helperText={
        props.value < 1 &&
        props.submittedOnce &&
        "O preço deve ser maior que R$ 0,00"
      }
      autoComplete="off"
      fullWidth
    />
  );
};

export default DynamicPriceInput;
