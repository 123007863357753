import ImageList from "@mui/material/ImageList/ImageList";

const ImageGalleryContainer = ({ children }: any) => {
  return (
    <ImageList cols={2} variant="quilted" rowHeight={320} sx={{width: "100%"}}>
      {children}
    </ImageList>
  );
};

export default ImageGalleryContainer;
