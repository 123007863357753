import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import WorkingScaleSelector from "../../../../../components/comercial/WorkingScaleSelector";
import employeeApi from "../../../../../services/api/Employee";
import { IEmployee } from "../../../../../services/api/Employee/types";
import { IStore } from "../../../../../services/api/Store/types";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import BackPageButton from "../../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../../styles/sharedComponentStyles";
import { sharedStyles } from "../../../../../styles/shared";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { useAuth } from "../../../../../context/AuthProvider/useAuth";

const EditEmployee = () => {
  const navigate = useNavigate();

  const selectedEmployee: IEmployee = getLocalStorageItem("selectedEmployee");

  const selectedStore: IStore = getLocalStorageItem("selectedStore");

  const auth = useAuth();

  const [timeMonday, setTimeMonday] = useState<string[]>(
    selectedEmployee.timeMonday
  );
  const [timeTuesday, setTimeTuesday] = useState<string[]>(
    selectedEmployee.timeTuesday
  );
  const [timeWednesday, setTimeWednesday] = useState<string[]>(
    selectedEmployee.timeWednesday
  );
  const [timeThursday, setTimeThursday] = useState<string[]>(
    selectedEmployee.timeThursday
  );
  const [timeFriday, setTimeFriday] = useState<string[]>(
    selectedEmployee.timeFriday
  );
  const [timeSaturday, setTimeSaturday] = useState<string[]>(
    selectedEmployee.timeSaturday
  );
  const [timeSunday, setTimeSunday] = useState<string[]>(
    selectedEmployee.timeSunday
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleEmployeeEdition = async () => {
    setIsLoading(!isLoading);
    try {
      await employeeApi.updateEmployee(selectedEmployee.id, {
        timeMonday,
        timeTuesday,
        timeWednesday,
        timeThursday,
        timeFriday,
        timeSaturday,
        timeSunday,
      });

      window.alert(`${selectedEmployee.User.name} editado com sucesso!`);
      navigate("/employees");
    } catch (error) {
      alert("Não foi possível editar o funcionário.");
      console.warn("Error editing a employee: ", error);
    }
    setIsLoading(false);
  };

  const renderWorkScaleSelector = (
    <>
      <ComercialInterfaceHeader
        title={
          selectedEmployee.User.id === auth.user.id
            ? `Seus horários em ${selectedEmployee.store.name.toUpperCase()}`
            : `Horários de ${selectedEmployee.User.name} em ${selectedEmployee.store.name.toUpperCase()}`
        }
      />
      <Box sx={sharedStyles.singleFormContainer}>
        <WorkingScaleSelector
          timeMonday={timeMonday}
          setTimeMonday={setTimeMonday}
          timeTuesday={timeTuesday}
          setTimeTuesday={setTimeTuesday}
          timeWednesday={timeWednesday}
          setTimeWednesday={setTimeWednesday}
          timeThursday={timeThursday}
          setTimeThursday={setTimeThursday}
          timeFriday={timeFriday}
          setTimeFriday={setTimeFriday}
          timeSaturday={timeSaturday}
          setTimeSaturday={setTimeSaturday}
          timeSunday={timeSunday}
          setTimeSunday={setTimeSunday}
          possibleMondayTimes={selectedStore.timeMonday}
          possibleTuesdayTimes={selectedStore.timeTuesday}
          possibleWednesdayTimes={selectedStore.timeWednesday}
          possibleThursdayTimes={selectedStore.timeThursday}
          possibleFridayTimes={selectedStore.timeFriday}
          possibleSaturdayTimes={selectedStore.timeSaturday}
          possibleSundayTimes={selectedStore.timeSunday}
        />
      </Box>
    </>
  );

  const renderOptionButtons = (
    <Box sx={sharedStyles.dividedFormContainer}>
      <PrimaryButton
        label="Confirmar adição"
        onClickAction={handleEmployeeEdition}
        icon={<PersonAddIcon />}
        isLoading={isLoading}
        isDisabled={isLoading}
      />

      <SecondaryButton
        label="Cancelar"
        isDisabled={isLoading}
        onClickAction={() => navigate("/employees")}
      />
    </Box>
  );
  const renderAddStorePageContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <form style={sharedStyles.form}>
        {renderWorkScaleSelector}
        {renderOptionButtons}
      </form>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default EditEmployee;
