import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  SxProps,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useState, lazy } from "react";
import { AppointmentDetailsModal } from "../../../../components/general/AppointmentDetailsModal";
import AppointmentStatusChip from "../../../../components/general/AppointmentStatusChip";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { IAppointment } from "../../../../services/api/Appointment/types";
import { formatDateAndTimeDayDateToString } from "../ScheduilingPage/timeUtils";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../styles/sharedComponentStyles";
import useFetchClientAppointments from "../../../../hooks/entities/useFetchClientAppointments";
import { sharedStyles } from "../../../../styles/shared";
import { isAppointmentFromCertainDate } from "../../../../utils/dateFunctions";
const AppointmentsCalendar = lazy(
  () => import("../../../../components/general/AppointmentsCalendar")
);

const ClientAppointments = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  const [succeededRatingPost, setSucceededRatingPost] = useState(false);

  const {
    userAppointments,
    userAppointmentsLoading,
    appointmentDates,
    getUserAppointmentsInfo,
  } = useFetchClientAppointments();

  const [selectedUserAppointment, setSelectedUserAppointment] = useState(null);

  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);

  const handleOpenModal = (selectedAppointment: IAppointment) => {
    setSelectedUserAppointment(selectedAppointment);
    setIsAppointmentModalOpen(true);
  };

  const handleCloseModal = () => setIsAppointmentModalOpen(false);

  const handleFinishService = () => {
    getUserAppointmentsInfo();
    handleCloseModal();
  };

  const AppointmentsModal = (
    <>
      <AppointmentDetailsModal
        open={isAppointmentModalOpen}
        appointment={selectedUserAppointment}
        onClose={handleCloseModal}
        onFinish={handleFinishService}
        setSucceededRatingPost={setSucceededRatingPost}
      />
      <Snackbar
        open={succeededRatingPost}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSucceededRatingPost(false)}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Serviço avaliado com sucesso!
        </Alert>
      </Snackbar>
    </>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      {AppointmentsModal}
      <ClientNavbar hideMidButton />

      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>

      <Box sx={styles.mainContainer}>
        {!userAppointmentsLoading ? (
          <>
            <Typography variant="h5" fontWeight="bold">
              Seus agendamentos
            </Typography>
            <Box sx={styles.appointmentsBoard}>
              <AppointmentsCalendar
                pendentAppointmentDates={appointmentDates?.pendent}
                confirmedAppointmentDates={appointmentDates?.confirmed}
                finishedAppointementDates={appointmentDates?.finished}
                canceledAppointmentDates={appointmentDates?.canceled}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />

              <Box sx={sharedStyles.singleFormContainer}>
                <Typography variant="h6" fontWeight={500}>
                  Dia {selectedDate?.format("DD/MM/YYYY")}
                </Typography>

                {userAppointments?.some(
                  (appointment) =>
                    appointment.date.split("T")[0] ===
                    selectedDate?.format("YYYY-MM-DD")
                ) ? (
                  <List
                    disablePadding
                    sx={{ ...sharedStyles.appointmentsList }}
                  >
                    {userAppointments.map(
                      (appointment, index) =>
                        isAppointmentFromCertainDate(
                          appointment,
                          selectedDate
                        ) && (
                          <ListItemButton
                            onClick={() =>
                              handleOpenModal(userAppointments[index])
                            }
                            key={appointment.id}
                            sx={sharedStyles.storeItemContent}
                          >
                            <ListItemText
                              primary={`${userAppointments[index].serviceEmployee.ServiceStore.Service.name} `}
                              secondary={`${formatDateAndTimeDayDateToString(
                                userAppointments[index].date
                              )} com ${
                                userAppointments[index].serviceEmployee.Employee
                                  .User.name
                              } (${
                                userAppointments[index].serviceEmployee.Employee
                                  .store.name
                              }) `}
                              primaryTypographyProps={{ fontWeight: 500 }}
                            />
                            <AppointmentStatusChip
                              status={userAppointments[index].status}
                            />
                          </ListItemButton>
                        )
                    )}
                  </List>
                ) : (
                  <Box>
                    <Typography color="text.secondary">
                      Nenhum agendamento para esta data.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Box>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ClientAppointments;

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    margin: "0px 30px 80px 30px",
  } as SxProps,

  appointmentsBoard: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",

    "@media(max-width:1000px)": {
      flexDirection: "column",
    },
  } as SxProps,
};
