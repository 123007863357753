import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SxProps,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ComercialDrawer from "../../../../../components/turism/ComercialDrawer";
import { useState } from "react";
import { type IStore } from "../../../../../services/api/Store/types";
import { cleanLocalStorage } from "../../../../../utils/cleanLocalStorage";
import IExcursion from "../../../../../services/api/Excursion/IExcursion";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useManagerStores from "../../../../../hooks/context-hooks/useManagerStores";
import useFetchManagerStores from "../../../../../hooks/entities/useFetchManagerStores";
import { setLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import { sharedStyles } from "../../../../../styles/shared";
import useFetchExcursionsFromStore from "../../../../../hooks/entities/useFetchExcursionsFromStore";
import {
  handleDeleteExcursionClick,
  handleSelectExcursion,
  handleSelectStore,
} from "./functions";
import { priceFormatter } from "../../../../../utils/priceFormatter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ComercialTable from "../../../../../components/comercial/ComercialTable";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import AddIcon from "@mui/icons-material/Add";
import defaultExcursion from "../../../../../assets/svg/defaultExcursion.svg";

const Excursions = () => {
  const navigate = useNavigate();

  cleanLocalStorage();

  const managerStoresContext = useManagerStores();
  const { stores } = managerStoresContext;
  const { loadingManagerStores } = useFetchManagerStores();

  const [selectedStoreIndex, setSelectedStoreIndex] = useState(0);

  const { excursions, excursionsLoading, fetchExcursions } =
    useFetchExcursionsFromStore(stores && stores[selectedStoreIndex]?.id);

  const isMobile = useMediaQuery("(max-width:800px)");

  const handleAddExcursionClick = () => {
    handleSelectStore(stores[selectedStoreIndex]);
    navigate("/excursions/add");
  };

  const handleEditExcursionClick = (store: IStore, excursion: IExcursion) => {
    setLocalStorageItem("storeData", {
      storeName: store.name,
      storeId: store.id,
    });
    handleSelectExcursion(excursion);
    navigate("/excursions/edit");
  };

  const handleSeeExcursionDetailsClick = (excursion: IExcursion) => {
    handleSelectExcursion(excursion);
    navigate("/excursions/details");
  };

  const excursionsTableHeader = (
    <>
      {!excursionsLoading && excursions && (
        <TableRow>
          <TableCell align="left">Excursão</TableCell>
          <TableCell align="right">Ações</TableCell>
        </TableRow>
      )}
    </>
  );

  const excursionsTableBody = (
    <>
      {!excursionsLoading ? (
        <>
          {excursions?.length > 0 ? (
            <>
              {excursions.map((excursion) => (
                <TableRow
                  key={excursion.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    component="th"
                    sx={{ ...sharedStyles.storeItemContent }}
                    align="left"
                  >
                    <Avatar
                      src={excursion.imagePreview[0] ?? defaultExcursion}
                      sx={sharedStyles.tableAvatarStyle}
                    />

                    <ListItemText
                      primary={excursion.title}
                      secondary={`Preço: R$ ${priceFormatter(excursion.price)}`}
                      primaryTypographyProps={{
                        fontWeight: 500,
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Detalhes">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleSeeExcursionDetailsClick(excursion)
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleEditExcursionClick(
                            stores[selectedStoreIndex],
                            excursion
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleDeleteExcursionClick(
                            excursion,
                            stores[selectedStoreIndex],
                            fetchExcursions
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell>
                <Typography color="text.secondary" variant="body2">
                  Não há excursões cadastradas neste estabelecimento
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </>
      ) : (
        <TableRow>
          <TableCell align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      )}
    </>
  );

  const renderExcursionsPageContent = (
    <Box>
      {!loadingManagerStores ? (
        <Box sx={styles.container}>
          {stores && stores.length > 0 ? (
            <>
              <ComercialInterfaceHeader
                title={`Excursões de ${
                  stores[selectedStoreIndex]?.name ?? "Carregando..."
                }`}
              />
              <Box>
                <Box sx={styles.filterAndAddContainer}>
                  <Box sx={{ maxWidth: "150px", width: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel>Estabelecimento</InputLabel>
                      <Select
                        label="Estabelecimento"
                        value={stores && selectedStoreIndex}
                        onChange={(e: any) =>
                          setSelectedStoreIndex(e.target.value as number)
                        }
                        size="small"
                      >
                        {stores &&
                          stores.map((store, index) => (
                            <MenuItem value={index} key={index}>
                              {store?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    {!isMobile ? (
                      <PrimaryButton
                        label="Adicionar excursão"
                        onClickAction={handleAddExcursionClick}
                        icon={<AddIcon />}
                      />
                    ) : (
                      <Button
                        onClick={handleAddExcursionClick}
                        color="primary"
                        variant="contained"
                        disableElevation
                      >
                        <AddIcon />
                      </Button>
                    )}
                  </Box>
                </Box>

                <Box>
                  <ComercialTable
                    header={excursionsTableHeader}
                    body={excursionsTableBody}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Typography textAlign="justify">
              Para cadastrar excursões é necessário{" "}
              <Typography
                color="primary"
                fontWeight="bold"
                onClick={() => navigate("/agencies/add")}
                sx={{ cursor: "pointer" }}
                variant="span"
              >
                cadastrar um estabelecimento
              </Typography>
              .
            </Typography>
          )}
        </Box>
      ) : (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderExcursionsPageContent} />
    </Box>
  );
};

export default Excursions;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  } as SxProps,

  avatarStyle: {
    width: "60px",
    height: "60px",
  },

  filterAndAddContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "50px",
    alignItems: "center",
  } as SxProps,

  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
