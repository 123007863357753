import { useEffect, useState } from "react";
import { IAppointment } from "../../services/api/Appointment/types";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { fetchUserAppointments } from "../../utils/fetchEntities";
import {
  AppointmentDates,
  getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments,
} from "../../utils/dateFunctions";

const useFetchClientAppointments = () => {
  const auth = useAuth();

  const [userAppointments, setUserAppointments] = useState<
    IAppointment[] | null
  >(null);
  const [appointmentDates, setAppointmentDates] =
    useState<AppointmentDates | null>(null);

  const [userAppointmentsLoading, setUserAppointmentsLoading] = useState(false);

  const getUserAppointmentsInfo = async () => {
    setUserAppointmentsLoading(true);

    try {
      const userAppointmentsResponse = await fetchUserAppointments(
        auth.user.id
      );

      setUserAppointments(userAppointmentsResponse);

      setAppointmentDates(
        getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments(
          userAppointmentsResponse
        )
      );
    } catch (error) {
      console.warn("Error getting user appointments info: ", error);
    }
    setUserAppointmentsLoading(false);
  };

  useEffect(() => {
    if (auth.user.id) getUserAppointmentsInfo();
  }, [auth.user]);

  return {
    userAppointments,
    userAppointmentsLoading,
    appointmentDates,
    getUserAppointmentsInfo,
  };
};

export default useFetchClientAppointments;
