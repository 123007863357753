import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { IEmployee } from "../../services/api/Employee/types";
import { fetchUserEmployees } from "../../utils/fetchEntities";

const useFetchUserEmployees = () => {
  const { user } = useAuth();
  const { id: userId } = user;

  const [userEmployees, setUserEmployees] = useState<IEmployee[] | null>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userId) return;

    setLoading(true);

    fetchUserEmployees(userId)
      .then((response) => {
        setUserEmployees(response);
      })
      .catch((error) => console.error("Erro ao buscar funcionários:", error))
      .finally(() => setLoading(false));
  }, [userId]);

  return { userEmployees, loading };
};

export default useFetchUserEmployees;
