import { yupResolver } from "@hookform/resolvers/yup";
import { Box, TextField, Alert } from "@mui/material";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { POSSIBLE_WORKING_TIMES } from "../../workingTimeConstants";
import {
  addAndEditStoreSchema,
  showCustomInputFieldError,
  validateCnpj,
  validatePhoneNumber,
} from "../storeValidationSchemas";
import InputMask from "react-input-mask";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EditIcon from "@mui/icons-material/Edit";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import WorkingScaleSelector from "../../../../../components/comercial/WorkingScaleSelector";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import {
  AddressProps,
  getCoordinatesByAddress,
} from "../../../../../hooks/getCoodinatesByAddress";
import storeApi from "../../../../../services/api/Store";
import { IStore } from "../../../../../services/api/Store/types";
import useCep from "../../../../../hooks/useCep";
import { sharedStyles } from "../../../../../styles/shared";
import BackPageButton from "../../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../../styles/sharedComponentStyles";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import ImageGallery from "../../../../../components/comercial/ImageGallery";
import {
  deleteImageFromFirebase,
  uploadOriginalAndPreviewImagesToFirebase,
} from "../../../../../utils/firebase/functions";
import { CURRENT_SUBDOMAIN, getStoreImagesFirebasePath } from "../../../../../utils/stringUtils";
import { handleDeleteExistingOriginalAndPreviewImageClick } from "../../ServicesPages/sharedFunctions";
import ImageGalleryContainer from "../../../../../components/comercial/ImageGallery/ImageGalleryContainer";

const EditStore = () => {
  const navigate = useNavigate();

  const selectedStore: IStore = getLocalStorageItem("selectedStore")!;

  const storeNameRef = useRef<HTMLInputElement>(null);
  const [cnpj, setCnpj] = useState<string>(selectedStore.CNPJ);
  const cnpjRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [phone, setPhone] = useState<string>(selectedStore.phone);
  const phoneRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const streetRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const districtRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const [cep, setCep] = useState<string>(selectedStore.CEP);

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const [newImages, setNewImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImageUrls, setOriginalImageUrls] = useState<string[]>(
    selectedStore.imageOriginal
  );
  const [previewImageUrls, setPreviewImageUrls] = useState<string[]>(
    selectedStore.imagePreview
  );

  const [timeMonday, setTimeMonday] = useState<string[]>(
    selectedStore.timeMonday
  );
  const [timeTuesday, setTimeTuesday] = useState<string[]>(
    selectedStore.timeTuesday
  );
  const [timeWednesday, setTimeWednesday] = useState<string[]>(
    selectedStore.timeWednesday
  );
  const [timeThursday, setTimeThursday] = useState<string[]>(
    selectedStore.timeThursday
  );
  const [timeFriday, setTimeFriday] = useState<string[]>(
    selectedStore.timeFriday
  );
  const [timeSaturday, setTimeSaturday] = useState<string[]>(
    selectedStore.timeSaturday
  );
  const [timeSunday, setTimeSunday] = useState<string[]>(
    selectedStore.timeSunday
  );

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  const [generalErrorMessage, setGeneralErrorMessage] = useState("");

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addAndEditStoreSchema) });

  const handleUpdateStore = async () => {
    setGeneralErrorMessage("");

    if (submittedOnce === false) setSubmittedOnce(true);

    const isCnpjValid = validateCnpj(cnpj);
    const isPhoneValid = validatePhoneNumber(phone);

    if (isCnpjValid && isPhoneValid) {
      setButtonsDisabled(!buttonsDisabled);

      try {
        for (let i = 0; i < selectedStore.imageOriginal.length; i++)
          !originalImageUrls.includes(selectedStore.imageOriginal[i]) &&
            (await Promise.all([
              deleteImageFromFirebase(selectedStore.imageOriginal[i]),
              deleteImageFromFirebase(selectedStore.imagePreview[i]),
            ]));

        let finalOriginalImages = originalImageUrls;
        let finalPreviewImages = previewImageUrls;

        if (newImages.length > 0) {
          const response = await uploadOriginalAndPreviewImagesToFirebase(
            newImages,
            getStoreImagesFirebasePath(selectedStore?.name, selectedStore?.id)
          );

          finalOriginalImages.push(...response.originalImageUrls);
          finalPreviewImages.push(...response.previewImageUrls);
        }

        const coordinatesResponse = await getCoordinatesByAddress({
          street: streetRef?.current?.value,
          number: numberRef?.current?.value,
          district: districtRef?.current?.value,
          city: cityRef?.current?.value,
          state: stateRef?.current?.value,
          country: "Brazil",
          postalCode: cep,
        } as AddressProps);

        const updatedStoreResponse = await storeApi.updateStore(
          selectedStore.id,
          {
            name: storeNameRef?.current?.value,
            email: emailRef?.current?.value,
            CNPJ: cnpj,
            phone: phone,
            description: descriptionRef?.current?.value,
            logradouro: streetRef?.current?.value,
            number: numberRef?.current?.value,
            district: districtRef?.current?.value,
            city: cityRef?.current?.value,
            state: stateRef?.current?.value,
            CEP: cep,
            country: "Brazil",
            lat: Number(coordinatesResponse[0]),
            lng: Number(coordinatesResponse[1]),
            imageOriginal: finalOriginalImages,
            imagePreview: finalPreviewImages,
            listTags: "",
            timeMonday,
            timeTuesday,
            timeWednesday,
            timeThursday,
            timeFriday,
            timeSaturday,
            timeSunday,
          } as IStore
        );

        window.alert(`${updatedStoreResponse.name} editado com sucesso!`);
        navigate("/stores");
      } catch (error) {
        setButtonsDisabled(false);
        console.warn("Error updating the agency: ", error);
      }
      setButtonsDisabled(buttonsDisabled);
      setGeneralErrorMessage(error.response.data.message);
    } else {
      !isCnpjValid ? cnpjRef.current.focus() : null;
      !isPhoneValid ? phoneRef.current.focus() : null;
    }
  };

  const foundedCepAddress = useCep(
    cep,
    streetRef,
    districtRef,
    cityRef,
    stateRef
  );

  const renderStoreData = (
    <>
      <ComercialInterfaceHeader title="Dados do estabelecimento " />
      <Box sx={sharedStyles.singleFormContainer}>
        <Box sx={sharedStyles.dividedFormContainer}>
          <TextField
            size="small"
            label="Nome do estabelecimento *"
            inputRef={storeNameRef}
            defaultValue={selectedStore.name}
            {...register("storeName")}
            error={!!errors.storeName}
            helperText={errors.storeName?.message}
            fullWidth
          />
          <InputMask
            mask="99.999.999/9999-99"
            disabled={false}
            maskChar="_"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
            error={showCustomInputFieldError(
              !validateCnpj(cnpj),
              submittedOnce
            )}
            helperText={
              showCustomInputFieldError(!validateCnpj(cnpj), submittedOnce)
                ? "CNPJ inválido."
                : ""
            }
            inputRef={cnpjRef}
          >
            <TextField size="small" fullWidth label="CNPJ *" />
          </InputMask>
        </Box>

        <Box sx={sharedStyles.dividedFormContainer}>
          <InputMask
            mask="(99) 9.9999-9999"
            disabled={false}
            maskChar="_"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            error={showCustomInputFieldError(
              !validatePhoneNumber(phone),
              submittedOnce
            )}
            helperText={
              showCustomInputFieldError(
                !validatePhoneNumber(phone),
                submittedOnce
              ) && "Telefone inválido."
            }
            inputRef={phoneRef}
          >
            <TextField label="Telefone *" size="small" fullWidth />
          </InputMask>
          <TextField
            size="small"
            label="Email para contato *"
            placeholder="Ex.: contato@email.com"
            fullWidth
            inputRef={emailRef}
            defaultValue={selectedStore.email}
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Box>
        <TextField
          size="small"
          label="Descrição"
          placeholder="Descreva brevemente seu estabelecimento..."
          defaultValue={selectedStore.description}
          fullWidth
          multiline
          rows={5}
          inputRef={descriptionRef}
        />
      </Box>
    </>
  );

  const renderAddressData = (
    <>
      <ComercialInterfaceHeader title="Dados geográficos do seu estabelecimento" />
      <Box sx={sharedStyles.singleFormContainer}>
        <InputMask
          mask="99999-999"
          defaultValue={selectedStore?.CEP}
          disabled={false}
          maskChar="_"
          value={cep}
          onChange={(e) => {
            setCep(e.target.value);
          }}
        >
          {<TextField size="small" label="CEP" fullWidth />}
        </InputMask>
        <Box sx={{ ...sharedStyles.singleFormContainer, flexDirection: "row" }}>
          <TextField
            size="small"
            label="Logradouro"
            defaultValue={selectedStore?.logradouro}
            inputRef={streetRef}
            sx={{ width: "75%" }}
            InputLabelProps={{ shrink: true }}
            disabled={foundedCepAddress !== null}
          />
          <TextField
            size="small"
            label="N°"
            defaultValue={selectedStore?.number}
            inputRef={numberRef}
            sx={{ width: "25%" }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <TextField
          size="small"
          label="Bairro"
          defaultValue={selectedStore?.district}
          fullWidth
          inputRef={districtRef}
          InputLabelProps={{ shrink: true }}
          disabled={foundedCepAddress !== null}
        />

        <Box sx={{ ...sharedStyles.singleFormContainer, flexDirection: "row" }}>
          <TextField
            size="small"
            label="Cidade"
            defaultValue={selectedStore?.city}
            disabled={foundedCepAddress !== null}
            fullWidth
            inputRef={cityRef}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            size="small"
            label="Estado"
            defaultValue={selectedStore?.state}
            fullWidth
            inputRef={stateRef}
            InputLabelProps={{ shrink: true }}
            disabled={foundedCepAddress !== null}
          />
        </Box>
      </Box>
    </>
  );

  const renderImageGalery = (
    <>
      <ImageGalleryHeader
        label="Adicionar"
        icon={<AddPhotoAlternateIcon />}
        setImages={setNewImages}
      />
      <ImageGalleryContainer>
        <ImageGallery
          images={originalImageUrls}
          setImages={setOriginalImageUrls}
          removeImageFunction={(index) =>
            handleDeleteExistingOriginalAndPreviewImageClick(
              index,
              setOriginalImageUrls,
              setPreviewImageUrls
            )
          }
          interable
        />
        <ImageGallery images={newImages} setImages={setNewImages} interable />
      </ImageGalleryContainer>
    </>
  );

  const renderWorkScaleSelector = (
    <>
      <ComercialInterfaceHeader title="Horários de funcionamento" />
      <Box>
        <WorkingScaleSelector
          timeMonday={timeMonday}
          setTimeMonday={setTimeMonday}
          timeTuesday={timeTuesday}
          setTimeTuesday={setTimeTuesday}
          timeWednesday={timeWednesday}
          setTimeWednesday={setTimeWednesday}
          timeThursday={timeThursday}
          setTimeThursday={setTimeThursday}
          timeFriday={timeFriday}
          setTimeFriday={setTimeFriday}
          timeSaturday={timeSaturday}
          setTimeSaturday={setTimeSaturday}
          timeSunday={timeSunday}
          setTimeSunday={setTimeSunday}
          possibleMondayTimes={POSSIBLE_WORKING_TIMES}
          possibleTuesdayTimes={POSSIBLE_WORKING_TIMES}
          possibleWednesdayTimes={POSSIBLE_WORKING_TIMES}
          possibleThursdayTimes={POSSIBLE_WORKING_TIMES}
          possibleFridayTimes={POSSIBLE_WORKING_TIMES}
          possibleSaturdayTimes={POSSIBLE_WORKING_TIMES}
          possibleSundayTimes={POSSIBLE_WORKING_TIMES}
        />
      </Box>
    </>
  );

  const renderEditStorePageContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <form onSubmit={onSubmit(handleUpdateStore)} style={sharedStyles.form}>
        {renderStoreData}
        {renderAddressData}
        {renderImageGalery}
        {renderWorkScaleSelector}
        {generalErrorMessage !== "" ? (
          <Alert severity="error">{generalErrorMessage}</Alert>
        ) : null}
        <Box sx={sharedStyles.dividedFormContainer}>
          <PrimaryButton
            label="Confirmar edição"
            isDisabled={buttonsDisabled}
            icon={<EditIcon />}
            isLoading={buttonsDisabled}
          />
          <SecondaryButton
            label="Cancelar"
            onClickAction={() => navigate("/stores")}
            isDisabled={buttonsDisabled}
          />
        </Box>
      </form>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderEditStorePageContent} />
    </Box>
  );
};

export default EditStore;
