import { Box, SxProps, Typography } from "@mui/material";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import { IServiceStore } from "../../../../../services/api/ServiceStore/types";
import { IUser } from "../../../../../services/api/User/types";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import BackPageButton from "../../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../../styles/sharedComponentStyles";
import { sharedStyles } from "../../../../../styles/shared";
import { WEEKDAY_LABELS } from "../../workingTimeConstants";
import { priceFormatter } from "../../../../../utils/priceFormatter";
import ImageGallery from "../../../../../components/comercial/ImageGallery";
import { generalDarkGrey } from "../../../../../styles/colors";
import { useEffect } from "react";
import ImageGalleryContainer from "../../../../../components/comercial/ImageGallery/ImageGalleryContainer";

const ServiceDetails = () => {
  const selectedServiceStore: IServiceStore = getLocalStorageItem(
    "selectedServiceStore"
  );

  const selectedServiceEmployeeUsers: IUser[] = getLocalStorageItem(
    "selectedServiceEmployeeUsers"
  );

  const renderServiceData = (
    <>
      <ComercialInterfaceHeader title="Dados do serviço" />
      <Box sx={sharedStyles.singleFormContainer}>
        <Box>
          <Typography fontWeight="bold" variant="h6" textAlign="center">
            {selectedServiceStore?.Service.name}
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight={500}>Descrição:</Typography>
          <Typography variant="body1" textAlign="justify">
            {selectedServiceStore?.description.length > 0
              ? selectedServiceStore?.description
              : "Este serviço não possui descrição"}
          </Typography>
        </Box>
      </Box>
    </>
  );

  const renderPrices = (
    <>
      <ComercialInterfaceHeader title="Preços" />
      <Box sx={sharedStyles.singleFormContainer}>
        <Box
          display="flex"
          flexWrap="wrap"
          textAlign="center"
          gap="20px"
          justifyContent="space-around"
        >
          {WEEKDAY_LABELS.map((dayLabel, index) => (
            <Box key={index}>
              <Typography fontWeight={500}>{`${dayLabel}`}</Typography>
              <Typography>{`R$ ${priceFormatter(
                selectedServiceStore.priceDay[index]
              )}`}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );

  const renderImageGallery = (
    <>
      <ComercialInterfaceHeader title="Galeria de fotos" />

      <ImageGalleryContainer>
        <ImageGallery images={selectedServiceStore?.imageOriginal} />
      </ImageGalleryContainer>
    </>
  );

  const renderSelectedEmployee = (
    <>
      <ComercialInterfaceHeader title="Funcionários do serviço" />
      <Box sx={sharedStyles.singleFormContainer}></Box>
    </>
  );

  const renderServiceDetailsContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <Box sx={styles.container}>
        {renderServiceData}
        {renderPrices}
        {renderImageGallery}
        {/* {renderSelectedEmployee} */}
      </Box>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderServiceDetailsContent} />
    </Box>
  );
};

export default ServiceDetails;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
  } as SxProps,
};
