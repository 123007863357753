import { handleSelectHTMLCanvaImage } from "../../../utils/image/imageFunctions";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { PrimaryButton } from "../../general/buttons/PrimaryButton";

interface AddImageInputProps {
  label: string;
  icon?: ReactNode;
  setImages: Dispatch<SetStateAction<HTMLCanvasElement[]>>;
}

const AddImageInput = (props: AddImageInputProps) => {
  const { label, icon, setImages } = props;

  return (
    <label htmlFor="image-input">
      <PrimaryButton
        label={label}
        icon={
          <>
            <input
              type="file"
              accept="image/*"
              multiple
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: "pointer",
              }}
              onChange={(e) => handleSelectHTMLCanvaImage(e, setImages)}
            />
            {icon}
          </>
        }
      />
    </label>
  );
};

export default AddImageInput;
