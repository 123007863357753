import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import CreateAppointmentModal from "./CreateAppointmentModal";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import ComercialDrawer from "../../../../components/pet-beauty/CormercialDrawer";
import { AppointmentDetailsModal } from "../../../../components/general/AppointmentDetailsModal";
import GenericModal from "../../../../components/general/GenericModal";
import AppointmentsCalendar from "../../../../components/general/AppointmentsCalendar";
import useFetchComercialAppointments from "../../../../hooks/entities/useFetchComercialAppointments";
import { PrimaryButton } from "../../../../components/general/buttons/PrimaryButton";
import { generalDarkGrey } from "../../../../styles/colors";
import { hasDateAppointments as certainDateHasAppointments } from "./functions";
import { sharedStyles } from "../../../../styles/shared";
import { formatDateAndTimeDayDateToString } from "../../Client/ScheduilingPage/timeUtils";
import AppointmentStatusChip from "../../../../components/general/AppointmentStatusChip";
import {
  AppointmentDates,
  getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments,
  isAppointmentFromCertainDate,
} from "../../../../utils/dateFunctions";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../styles/sharedComponentStyles";
import { IAppointment } from "../../../../services/api/Appointment/types";
import { handleFinishAppointmentClick } from "../../../../components/general/AppointmentDetailsModal/functions";

const ComercialAppointments = () => {
  const auth = useAuth();

  const isMobile = useMediaQuery("(max-width:1000px)");

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  const {
    appointments,
    appointmentsLoading,
    employeeStores,
    fetchAllUserRelatedAppointments,
  } = useFetchComercialAppointments();

  const [selectedStoreId, setSelectedStoreId] = useState<string>("-");

  const [selectedAppointment, setSelectedAppointment] =
    useState<IAppointment | null>(null);

  const [finishAppointmentLoading, setFinishAppointmentLoading] =
    useState(false);

  const handleOpenAppointmentDetailsModal = (
    selectedAppointment: IAppointment
  ) => setSelectedAppointment(selectedAppointment);

  const handleFinishAppointment = async () => {
    setFinishAppointmentLoading(true);
    await handleFinishAppointmentClick(selectedAppointment?.id);
    await fetchAllUserRelatedAppointments();

    setSelectedAppointment(null);
    setFinishAppointmentLoading(false);
  };

  useEffect(() => {
    employeeStores && employeeStores.length > 0 && setSelectedStoreId("-");
  }, [employeeStores]);

  const filteredAppointments =
    selectedStoreId === "-" || !selectedStoreId
      ? appointments
      : appointments.filter(
          (appointment) =>
            appointment.serviceEmployee.Employee.storeId === selectedStoreId
        );

  const appointmentDates: AppointmentDates = appointments
    ? getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments(
        filteredAppointments
      )
    : { pendent: [], canceled: [], confirmed: [], finished: [] };

  const renderAppointmentsCalendar = (
    <AppointmentsCalendar
      pendentAppointmentDates={appointmentDates?.pendent}
      confirmedAppointmentDates={appointmentDates?.confirmed}
      canceledAppointmentDates={appointmentDates?.canceled}
      finishedAppointementDates={appointmentDates?.finished}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
    />
  );

  const renderAppointmentsList = (
    <Box
      width={isMobile ? "100%" : "38%"}
      sx={{ ...sharedStyles.singleFormContainer }}
    >
      <PrimaryButton
        label={` Criar agendamento (${selectedDate?.format("DD/MM/YYYY")})`}
        icon={<AddAlertIcon />}
        onClickAction={() => {}}
      />

      <Box
        sx={{
          ...sharedStyles.storeItemContent,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">
          {selectedDate?.format("DD/MM/YYYY")}
        </Typography>

        <FormControl sx={{ width: "170px" }}>
          <InputLabel shrink>Estabelecimento</InputLabel>
          <Select
            notched
            label="Estabelecimento"
            value={selectedStoreId}
            onChange={(e) => setSelectedStoreId(e.target.value)}
            size="small"
          >
            {employeeStores?.map((store) => (
              <MenuItem key={store?.id} value={store.id}>
                <ListItem disablePadding>
                  <ListItemText primary={store?.name} />
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {certainDateHasAppointments(filteredAppointments, selectedDate) ? (
        <List
          sx={{
            ...sharedStyles.appointmentsList,
            height: "330px",
            overflowY: "auto",
          }}
          disablePadding
        >
          {filteredAppointments.map(
            (appointment, index) =>
              isAppointmentFromCertainDate(appointment, selectedDate) && (
                <ListItemButton
                  onClick={() => handleOpenAppointmentDetailsModal(appointment)}
                  key={appointment.id}
                  sx={sharedStyles.storeItemContent}
                >
                  <ListItemText
                    primary={`${filteredAppointments[index].serviceEmployee.ServiceStore.Service.name} `}
                    secondary={`${formatDateAndTimeDayDateToString(
                      filteredAppointments[index].date
                    )} com ${
                      filteredAppointments[index].serviceEmployee.Employee.User
                        .name
                    } (${
                      filteredAppointments[index].serviceEmployee.Employee.store
                        .name
                    }) `}
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                  <AppointmentStatusChip
                    status={filteredAppointments[index].status}
                  />
                </ListItemButton>
              )
          )}
        </List>
      ) : (
        <Box>
          <Typography color={generalDarkGrey}>
            Nenhum agendamento para esta data.
          </Typography>
        </Box>
      )}
    </Box>
  );

  const renderAppointmentsSection = (
    <Box sx={sharedStyles.singleFormContainer}>
      {!appointmentsLoading ? (
        <>
          <Box sx={{ ...backButtonContainer, margin: "0px 0px 0px -12px" }}>
            <BackPageButton />
          </Box>
          <Box
            sx={{
              ...sharedStyles.dividedFormContainer,
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            {renderAppointmentsCalendar}
            {renderAppointmentsList}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            ...sharedStyles.singleFormContainer,
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <GenericModal
        onClose={() => {}}
        open={false}
        content={
          <CreateAppointmentModal
            
          />
        }
      />
      <AppointmentDetailsModal
        appointment={selectedAppointment}
        onClose={() => setSelectedAppointment(null)}
        onFinish={handleFinishAppointment}
        showClient
        loading={finishAppointmentLoading}
      />
      <ComercialDrawer content={renderAppointmentsSection} />
    </Box>
  );
};

export default ComercialAppointments;
