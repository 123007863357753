import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_WORKING_TIME,
  POSSIBLE_WORKING_TIMES,
} from "../../workingTimeConstants";
import {
  addAndEditStoreSchema,
  showCustomInputFieldError,
  validateCnpj,
  validatePhoneNumber,
} from "../storeValidationSchemas";
import InputMask from "react-input-mask";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import ImageGallery from "../../../../../components/comercial/ImageGallery";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import WorkingScaleSelector from "../../../../../components/comercial/WorkingScaleSelector";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import {
  AddressProps,
  getCoordinatesByAddress,
} from "../../../../../hooks/getCoodinatesByAddress";
import storeApi from "../../../../../services/api/Store";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import { IStore } from "../../../../../services/api/Store/types";
import useCep from "../../../../../hooks/useCep";
import { sharedStyles } from "../../../../../styles/shared";
import BackPageButton from "../../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../../styles/sharedComponentStyles";
import { CURRENT_SUBDOMAIN, getStoreImagesFirebasePath } from "../../../../../utils/stringUtils";
import { uploadOriginalAndPreviewImagesToFirebase } from "../../../../../utils/firebase/functions";
import ImageGalleryContainer from "../../../../../components/comercial/ImageGallery/ImageGalleryContainer";

const AddStore = () => {
  const navigate = useNavigate();

  const storeNameRef = useRef<HTMLInputElement>(null);
  const [cnpj, setCnpj] = useState<string>("");
  const cnpjRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [phone, setPhone] = useState<string>("");
  const phoneRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const streetRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const districtRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const [cep, setCep] = useState<string>("");

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const [images, setImages] = useState<HTMLCanvasElement[]>([]);

  const [timeMonday, setTimeMonday] = useState<string[]>(DEFAULT_WORKING_TIME);
  const [timeTuesday, setTimeTuesday] =
    useState<string[]>(DEFAULT_WORKING_TIME);
  const [timeWednesday, setTimeWednesday] =
    useState<string[]>(DEFAULT_WORKING_TIME);
  const [timeThursday, setTimeThursday] =
    useState<string[]>(DEFAULT_WORKING_TIME);
  const [timeFriday, setTimeFriday] = useState<string[]>(DEFAULT_WORKING_TIME);
  const [timeSaturday, setTimeSaturday] = useState<string[]>([]);
  const [timeSunday, setTimeSunday] = useState<string[]>([]);

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  const [generalErrorMessage, setGeneralErrorMessage] = useState("");

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addAndEditStoreSchema) });

  const handleStoreCreation = async () => {
    setGeneralErrorMessage("");

    if (submittedOnce === false) setSubmittedOnce(true);

    const isCnpjValid = validateCnpj(cnpj);
    const isPhoneValid = validatePhoneNumber(phone);

    if (isCnpjValid && isPhoneValid) {
      setButtonsDisabled(!buttonsDisabled);
      try {
        const coordinatesResponse = await getCoordinatesByAddress({
          street: streetRef?.current?.value,
          number: numberRef?.current?.value,
          district: districtRef?.current?.value,
          city: cityRef?.current?.value,
          state: stateRef?.current?.value,
          country: "Brazil",
          postalCode: cep,
        } as AddressProps);

        const newStore: IStore = {
          name: storeNameRef?.current?.value,
          email: emailRef?.current?.value,
          CNPJ: cnpj,
          phone: phoneRef?.current?.value,
          description: descriptionRef?.current?.value,
          logradouro: streetRef?.current?.value,
          number: numberRef?.current?.value,
          district: districtRef?.current?.value,
          city: cityRef?.current?.value,
          state: stateRef?.current?.value,
          CEP: cep,
          country: "Brazil",
          lat: Number(coordinatesResponse[0]),
          lng: Number(coordinatesResponse[1]),
          imageOriginal: [],
          imagePreview: [],
          listTags: "",
          timeMonday,
          timeTuesday,
          timeWednesday,
          timeThursday,
          timeFriday,
          timeSaturday,
          timeSunday,
        };

        const createdStoreResponse = await storeApi.createStore(newStore);

        if (images.length > 0) {
          const { originalImageUrls, previewImageUrls } =
            await uploadOriginalAndPreviewImagesToFirebase(
              images,
              getStoreImagesFirebasePath(
                createdStoreResponse?.name,
                createdStoreResponse?.id
              )
            );

          newStore.imageOriginal = originalImageUrls;
          newStore.imagePreview = previewImageUrls;

          await storeApi.updateStore(createdStoreResponse.id, newStore);
        }

        window.alert(
          `O estabelecimento ${newStore.name} foi adicionado com sucesso!`
        );
        navigate("/stores");
      } catch (error) {
        setButtonsDisabled(false);
        console.warn("Error adding store: ", error);
        setGeneralErrorMessage(error.response.data.message);
      }
    } else {
      !isCnpjValid ? cnpjRef.current.focus() : null;
      !isPhoneValid ? phoneRef.current.focus() : null;
    }
  };

  const foundedCepAddress = useCep(
    cep,
    streetRef,
    districtRef,
    cityRef,
    stateRef
  );

  const renderStoreData = (
    <>
      <ComercialInterfaceHeader title="Dados do estabelecimento" />
      <Box sx={sharedStyles.singleFormContainer}>
        <Box sx={sharedStyles.dividedFormContainer}>
          <TextField
            size="small"
            label="Nome do estabelecimento *"
            inputRef={storeNameRef}
            {...register("storeName")}
            error={!!errors.storeName}
            helperText={errors.storeName?.message}
            fullWidth
          />
          <InputMask
            mask="99.999.999/9999-99"
            disabled={false}
            maskChar="_"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
            error={showCustomInputFieldError(
              !validateCnpj(cnpj),
              submittedOnce
            )}
            helperText={
              showCustomInputFieldError(!validateCnpj(cnpj), submittedOnce) &&
              "CNPJ inválido."
            }
            inputRef={cnpjRef}
          >
            <TextField size="small" fullWidth label="CNPJ *" />
          </InputMask>
        </Box>

        <Box sx={sharedStyles.dividedFormContainer}>
          <InputMask
            mask="(99) 9.9999-9999"
            disabled={false}
            maskChar="_"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            error={showCustomInputFieldError(
              !validatePhoneNumber(phone),
              submittedOnce
            )}
            helperText={
              showCustomInputFieldError(
                !validatePhoneNumber(phone),
                submittedOnce
              )
                ? "Telefone inválido."
                : ""
            }
            inputRef={phoneRef}
          >
            <TextField label="Telefone *" size="small" fullWidth />
          </InputMask>
          <TextField
            size="small"
            label="Email para contato *"
            placeholder="Ex.: contato@email.com"
            fullWidth
            inputRef={emailRef}
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Box>
        <TextField
          size="small"
          label="Descrição"
          placeholder="Descreva brevemente seu estabelecimento..."
          fullWidth
          multiline
          rows={5}
          inputRef={descriptionRef}
        />
      </Box>
    </>
  );

  const renderAddressData = (
    <>
      <ComercialInterfaceHeader title="Dados geográficos do estabelecimento" />
      <Box sx={sharedStyles.singleFormContainer}>
        <InputMask
          mask="99999-999"
          disabled={false}
          maskChar="_"
          value={cep}
          onChange={(e) => setCep(e.target.value)}
        >
          {<TextField size="small" label="CEP" fullWidth />}
        </InputMask>
        <Box sx={{ ...sharedStyles.singleFormContainer, flexDirection: "row" }}>
          <TextField
            size="small"
            label="Logradouro"
            inputRef={streetRef}
            sx={{ width: "75%" }}
            InputLabelProps={{ shrink: true }}
            disabled={foundedCepAddress !== null}
          />
          <TextField
            size="small"
            label="N°"
            inputRef={numberRef}
            sx={{ width: "25%" }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <TextField
          size="small"
          label="Bairro"
          fullWidth
          inputRef={districtRef}
          InputLabelProps={{ shrink: true }}
          disabled={foundedCepAddress !== null}
        />
        <Box sx={{ ...sharedStyles.singleFormContainer, flexDirection: "row" }}>
          <TextField
            size="small"
            label="Cidade"
            fullWidth
            inputRef={cityRef}
            InputLabelProps={{ shrink: true }}
            disabled={foundedCepAddress !== null}
          />

          <TextField
            size="small"
            label="Estado"
            fullWidth
            inputRef={stateRef}
            InputLabelProps={{ shrink: true }}
            disabled={foundedCepAddress !== null}
          />
        </Box>
      </Box>
    </>
  );

  const renderImageGalery = (
    <>
      <ImageGalleryHeader
        label="Adicionar"
        icon={<AddPhotoAlternateIcon />}
        setImages={setImages}
      />

      <ImageGalleryContainer>
        <ImageGallery images={images} setImages={setImages} interable />
      </ImageGalleryContainer>
    </>
  );

  const renderWorkScaleSelector = (
    <>
      <ComercialInterfaceHeader title="Horários de funcionamento" />
      <Box>
        <Typography>
          Horário padrão de funcionamento: <b>7:00-11:00</b> e{" "}
          <b>13:00-17:00</b>.
        </Typography>

        <Typography>Sinta-se à vontade para personalizá-lo!</Typography>
      </Box>
      <Box>
        <WorkingScaleSelector
          timeMonday={timeMonday}
          setTimeMonday={setTimeMonday}
          timeTuesday={timeTuesday}
          setTimeTuesday={setTimeTuesday}
          timeWednesday={timeWednesday}
          setTimeWednesday={setTimeWednesday}
          timeThursday={timeThursday}
          setTimeThursday={setTimeThursday}
          timeFriday={timeFriday}
          setTimeFriday={setTimeFriday}
          timeSaturday={timeSaturday}
          setTimeSaturday={setTimeSaturday}
          timeSunday={timeSunday}
          setTimeSunday={setTimeSunday}
          possibleMondayTimes={POSSIBLE_WORKING_TIMES}
          possibleTuesdayTimes={POSSIBLE_WORKING_TIMES}
          possibleWednesdayTimes={POSSIBLE_WORKING_TIMES}
          possibleThursdayTimes={POSSIBLE_WORKING_TIMES}
          possibleFridayTimes={POSSIBLE_WORKING_TIMES}
          possibleSaturdayTimes={POSSIBLE_WORKING_TIMES}
          possibleSundayTimes={POSSIBLE_WORKING_TIMES}
        />
      </Box>
    </>
  );

  const renderAddStorePageContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <form onSubmit={onSubmit(handleStoreCreation)} style={sharedStyles.form}>
        {renderStoreData}
        {renderAddressData}
        {renderImageGalery}
        {renderWorkScaleSelector}
        {generalErrorMessage !== "" ? (
          <Alert severity="error">{generalErrorMessage}</Alert>
        ) : null}
        <Box sx={sharedStyles.dividedFormContainer}>
          <PrimaryButton
            label="Confirmar adição"
            isDisabled={buttonsDisabled}
            isLoading={buttonsDisabled}
            icon={<AddBusinessIcon />}
          />
          <SecondaryButton
            label="Cancelar"
            onClickAction={() => navigate("/stores")}
            isDisabled={buttonsDisabled}
          />
        </Box>
      </form>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default AddStore;
