import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { useNavigate } from "react-router-dom";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Button,
  Divider,
  PopoverOrigin,
  SxProps,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { generalWhite } from "../../../../styles/colors";
import { UserRole } from "../../../../types/types";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
} from "../../../../utils/stringUtils";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import MobileMenuItem from "./MobileMenuItem";
import { useState } from "react";
import MenuItem from "./MenuItem";
import { handleLogout } from "../../../../utils/miscelaneous";

interface ClientNavbarProps {
  isUserComercial?: boolean;
  hideMidButton?: boolean;
  hideMenuButton?: boolean;
}

const anchorProps: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const menuItemStyle = { color: generalWhite };

const ClientNavbar = (props: ClientNavbarProps) => {
  const isMobile = useMediaQuery("(max-width:500px)");

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const auth = useAuth();
  const navigate = useNavigate();

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const handleMenuClose = () => handleMobileMenuClose();

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setMobileMoreAnchorEl(event.currentTarget);

  const handleLogoutClick = () => {
    window.confirm("Tem certeza que deseja sair?") &&
      handleLogout(auth, navigate);
    handleMenuClose();
  };

  const mobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={anchorProps}
      keepMounted
      transformOrigin={anchorProps}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {CURRENT_SUBDOMAIN !== DEMANDORIA_SUBDOMAINS[2] && (
        <MobileMenuItem
          icon={<CalendarMonthIcon sx={{ color: "text.primary" }} />}
          onClick={() => {
            navigate("/my-appointments");
          }}
          label={<Typography>Agendamentos</Typography>}
        />
      )}

      {!props.isUserComercial && (
        <MobileMenuItem
          icon={<ShoppingCartIcon sx={{ color: "text.primary" }} />}
          onClick={() => navigate("/cart")}
          label={<Typography>Carrinho de compras</Typography>}
        />
      )}

      <MobileMenuItem
        icon={<AccountCircle sx={{ color: "text.primary" }} />}
        onClick={() => navigate("/user/details")}
        label={<Typography>Meu perfil</Typography>}
      />

      <Divider />

      <MobileMenuItem
        icon={<LogoutIcon color="error" />}
        onClick={handleLogoutClick}
        label={<Typography color="error">Sair</Typography>}
      />
    </Menu>
  );

  return (
    <>
      <AppBar position="static" sx={styles.appBar}>
        <Toolbar>
          <Button sx={styles.logo} disableRipple>
            <Typography
              variant="h4"
              fontWeight="bold"
              onClick={() => {
                auth.user.role === UserRole.CLIENT
                  ? navigate("/home")
                  : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0] ||
                      CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
                    ? navigate("/stores")
                    : navigate("/agencies");
              }}
            >
              {isMobile ? "D" : "Demandoria"}
            </Typography>
          </Button>

          <Box sx={{ flexGrow: 1 }} />

          {!props.hideMidButton && (
            <Box>
              <PrimaryButton
                label="Busca avançada"
                icon={<ManageSearchIcon />}
                onClickAction={() => navigate("/search")}
                styles={styles.advancedSearchButton}
              />
            </Box>
          )}

          <Box sx={{ flexGrow: 1 }} />

          {!props.hideMenuButton && (
            <>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {CURRENT_SUBDOMAIN !== DEMANDORIA_SUBDOMAINS[2] ? (
                  <Tooltip title="Agendamentos">
                    <MenuItem
                      icon={<CalendarMonthIcon sx={menuItemStyle} />}
                      onClick={() => navigate("/my-appointments")}
                    />
                  </Tooltip>
                ) : null}
                {!props.isUserComercial && (
                  <Tooltip title="Carrinho de compras">
                    <MenuItem
                      icon={<ShoppingCartIcon sx={menuItemStyle} />}
                      onClick={() => navigate("/cart")}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Minha conta">
                  <MenuItem
                    icon={<AccountCircle sx={menuItemStyle} />}
                    onClick={() => navigate("/user/details")}
                  />
                </Tooltip>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <MenuItem
                  icon={<MoreIcon sx={menuItemStyle} />}
                  onClick={handleMobileMenuOpen}
                />
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      {!props.hideMenuButton && mobileMenu}
    </>
  );
};

export default ClientNavbar;

const styles = {
  appBar: {
    backgroundColor: "primary.main",
    width: "100vw",
    boxShadow: "none",
  } as SxProps,

  logo: {
    color: generalWhite,
    textTransform: "none",
  } as SxProps,

  advancedSearchButton: {
    backgroundColor: "secondary.main",
    color: "primary.main",

    ":hover": {
      color: generalWhite,
    },
    fontWeight: "bold",
    maxHeight: "40px",
  } as SxProps,
};
