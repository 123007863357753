import { Box, SxProps, Typography } from "@mui/material";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import TimeRangeInterpreter from "../../../../../components/comercial/TimeRangeInterpreter";
import { IStore } from "../../../../../services/api/Store/types";
import { generalDarkGrey } from "../../../../../styles/colors";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import BackPageButton from "../../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../../styles/sharedComponentStyles";
import { sharedStyles } from "../../../../../styles/shared";
import ImageGallery from "../../../../../components/comercial/ImageGallery";
import ContactIcon from "../../../../../components/general/ContactIcon";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import ImageGalleryContainer from "../../../../../components/comercial/ImageGallery/ImageGalleryContainer";

const StoreDetails = () => {
  const selectedStore: IStore = getLocalStorageItem("selectedStore");

  const renderStoreData = (
    <>
      <ComercialInterfaceHeader title="Dados do estabelecimento" />

      <Box sx={sharedStyles.singleFormContainer}>
        <Typography fontWeight="bold" variant="h6" textAlign="center">
          {`${selectedStore.name} (${selectedStore.CNPJ})`}
        </Typography>

        <Box sx={styles.singleContact}>
          <ContactIcon icon={<FmdGoodOutlinedIcon color="primary" />} />
          <Typography>
            {`${selectedStore.logradouro}, ${selectedStore.number || "s/n"} - ${
              selectedStore.district
            }, ${selectedStore.city} - ${selectedStore.state}, ${
              selectedStore.CEP
            }`}
          </Typography>
        </Box>

        <Box sx={styles.singleContact}>
          <ContactIcon icon={<LocalPhoneIcon color="primary" />} />
          <Typography>{selectedStore?.phone}</Typography>
        </Box>
        <Box sx={styles.singleContact}>
          <ContactIcon icon={<AlternateEmailIcon color="primary" />} />
          <Typography>{selectedStore?.email}</Typography>
        </Box>
      </Box>

      <Box>
        <Typography fontWeight="bold">Descrição:</Typography>

        {selectedStore.description?.length > 0 ? (
          <Typography variant="body1" textAlign="justify">
            {selectedStore.description}
          </Typography>
        ) : (
          <Typography variant="body1" textAlign="justify">
            Este estabelecimento não possui descrição.
          </Typography>
        )}
      </Box>
    </>
  );

  const renderImageGallery = (
    <>
      <ComercialInterfaceHeader title="Galeria de fotos" />
      
      <ImageGalleryContainer>
        <ImageGallery images={selectedStore.imageOriginal} />
      </ImageGalleryContainer>
    </>
  );

  const renderWorkScale = (
    <>
      <ComercialInterfaceHeader title="Horários de funcionamento" />
      <Box sx={sharedStyles.singleFormContainer}>
        <TimeRangeInterpreter
          timeMonday={selectedStore.timeMonday}
          timeTuesday={selectedStore.timeTuesday}
          timeWednesday={selectedStore.timeWednesday}
          timeThursday={selectedStore.timeThursday}
          timeFriday={selectedStore.timeFriday}
          timeSaturday={selectedStore.timeSaturday}
          timeSunday={selectedStore.timeSunday}
        />
      </Box>
    </>
  );

  const renderAddStorePageContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <div style={sharedStyles.form}>
        {renderStoreData}
        {renderImageGallery}
        {renderWorkScale}
      </div>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default StoreDetails;

const styles = {
  singleContact: { display: "flex", alignItems: "center", gap: 1 } as SxProps,
};
