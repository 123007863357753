import { useEffect, useState } from "react";
import useFetchUserEmployees from "./useFetchUserEmployedStores";
import { IAppointment } from "../../services/api/Appointment/types";
import { isUserStoreManager } from "../../pages/petAndBeauty/Comercial/Appointments/functions";
import { useAuth } from "../../context/AuthProvider/useAuth";
import appointmentApi from "../../services/api/Appointment";
import { StoreData } from "../../types/types";

const useFetchComercialAppointments = () => {
  const auth = useAuth();

  const { userEmployees } = useFetchUserEmployees();

  const [appointments, setAppointments] = useState<IAppointment[] | null>(null);
  const [appointmentsLoading, setAppointmentsLoading] = useState(true);

  const [employeeStores, setEmployeeStores] = useState<StoreData[] | null>(
    null
  );

  const fetchAllUserRelatedAppointments = async () => {
    try {
      let iterableStoreAppointmentsResponse = null;
      let allStoreAppointments = [];
      let auxiliarEmployeeStores = [{ id: "-", name: "Todos" }];

      for (const employee of userEmployees) {
        auxiliarEmployeeStores.push({
          id: employee.storeId,
          name: employee.store.name,
        });

        iterableStoreAppointmentsResponse = await (isUserStoreManager(
          auth.user.id,
          employee.store.managerId
        )
          ? appointmentApi.getAppointmentsFromStore(employee.storeId)
          : appointmentApi.getAppointmentsFromStoreAndEmployee(
              employee.storeId,
              employee.id
            ));

        allStoreAppointments.push(...iterableStoreAppointmentsResponse);
      }

      setEmployeeStores(auxiliarEmployeeStores);
      setAppointments(allStoreAppointments);
    } catch (error) {
      console.warn("Error fetching all user related appointments: ", error);
    }
  };

  useEffect(() => {
    userEmployees &&
      fetchAllUserRelatedAppointments().finally(() =>
        setAppointmentsLoading(false)
      );
  }, [userEmployees]);

  return {
    appointments,
    appointmentsLoading,
    employeeStores,
    fetchAllUserRelatedAppointments,
  };
};

export default useFetchComercialAppointments;
