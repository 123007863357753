import {
  Modal,
  Box,
  Typography,
  IconButton,
  SxProps,
  Button,
  TextField,
  Collapse,
} from "@mui/material";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { IAppointment } from "../../../services/api/Appointment/types";
import CloseIcon from "@mui/icons-material/Close";
import { formatDateAndTimeDayDateToString } from "../../../pages/petAndBeauty/Client/ScheduilingPage/timeUtils";
import AppointmentStatusChip from "../AppointmentStatusChip";
import { sharedStyles } from "../../../styles/shared";
import { generalDarkGrey } from "../../../styles/colors";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AppointmentStatus, UserRole } from "../../../types/types";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { handleCancelAppointmentClick } from "./functions";

interface AppointmentDetailsModalProps {
  onClose: VoidFunction;
  onFinish: VoidFunction;
  loading?: boolean;
  appointment: IAppointment;
  onConfirm?: any;
  showClient?: boolean;
  showRating?: boolean;
  setSucceededRatingPost?: Dispatch<SetStateAction<boolean>>;
}

export const AppointmentDetailsModal = (
  props: AppointmentDetailsModalProps
) => {
  const auth = useAuth();

  const { onClose, appointment, onFinish, loading } = props;
  const [wantsToCancel, setWantsToCancel] = useState(false);

  const cancellationReasonRef = useRef<HTMLInputElement>(null);

  const appointmentEmployee = appointment?.serviceEmployee.Employee;

  const isAppointmentFinishable =
    appointment?.status === AppointmentStatus.PENDENT ||
    appointment?.status === AppointmentStatus.CONFIRMED;

  const appointmentItem = (label: string, value: string) => (
    <Typography fontWeight={500}>
      {label}
      <span style={{ fontWeight: "normal", textAlign: "justify" }}>
        {value}
      </span>
    </Typography>
  );

  const handleOnClose = () => {
    onClose();
    setWantsToCancel(false);
  };

  return (
    <Modal open={appointment !== null} onClose={handleOnClose}>
      <Box sx={styles.container}>
        <IconButton
          onClick={handleOnClose}
          sx={{ margin: "-20px", alignSelf: "flex-end" }}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={sharedStyles.singleFormContainer}>
          <Box
            sx={{
              ...sharedStyles.singleFormContainer,
              gap: "0px",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Detalhes do serviço</Typography>
            {appointment?.date && (
              <Typography
                color={generalDarkGrey}
              >{`Agendado em ${formatDateAndTimeDayDateToString(
                appointment?.date
              )}`}</Typography>
            )}
          </Box>

          <Box sx={{ ...sharedStyles.singleFormContainer, gap: "10px" }}>
            {appointmentItem(
              "Serviço: ",
              appointment?.serviceEmployee.ServiceStore.Service.name
            )}

            {appointmentItem(
              "Estabelecimento: ",
              appointmentEmployee?.store.name
            )}

            {appointmentItem(
              "Cliente: ",
              appointment?.clientName ?? "Não consta"
            )}

            {appointmentItem(
              "Profissional: ",
              `${appointmentEmployee?.User.name} ${
                appointmentEmployee?.userId === auth.user.id ? "(você)" : ""
              }`
            )}

            {appointmentItem(
              "Data: ",
              appointment
                ? formatDateAndTimeDayDateToString(appointment?.date)
                : "Carregando..."
            )}

            <Box sx={sharedStyles.storeItemContent}>
              <Typography fontWeight={500}>{"Status: "}</Typography>
              <AppointmentStatusChip status={appointment?.status} />
            </Box>

            {appointment?.cancelationReason &&
              appointment?.status === AppointmentStatus.CANCELED && (
                <Box>
                  <Typography fontWeight={500}>
                    Motivo do cancelamento:
                  </Typography>
                  <Typography textAlign="justify">{appointment?.cancelationReason}</Typography>
                </Box>
              )}
          </Box>

          {isAppointmentFinishable && auth.user.role !== UserRole.CLIENT && (
            <PrimaryButton
              label="Finalizar serviço"
              icon={<CheckCircleIcon />}
              onClickAction={onFinish}
              isLoading={loading}
              isDisabled={loading}
            />
          )}

          {isAppointmentFinishable && (
            <Box sx={{ ...sharedStyles.singleFormContainer, gap: "10px" }}>
              <Button
                fullWidth
                sx={{ textTransform: "none" }}
                disableElevation
                endIcon={wantsToCancel ? <ExpandLess /> : <ExpandMore />}
                onClick={() => setWantsToCancel(!wantsToCancel)}
                color="error"
              >
                Deseja cancelar o serviço?
              </Button>
              <Collapse in={wantsToCancel} timeout="auto" unmountOnExit>
                <Box sx={sharedStyles.singleFormContainer}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Motivo de cancelamento (opcional)"
                    rows={2}
                    multiline
                    inputRef={cancellationReasonRef}
                  />
                  <Button
                    fullWidth
                    sx={{ textTransform: "none" }}
                    disableElevation
                    startIcon={<EventBusyIcon />}
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleCancelAppointmentClick(
                        cancellationReasonRef.current.value,
                        appointment.id,
                        appointment?.serviceEmployeeId
                      )
                    }
                  >
                    Cancelar serviço
                  </Button>
                </Box>
              </Collapse>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    borderRadius: "10px",
    bgcolor: "background.paper",
    p: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "@media (max-width: 600px)": {
      width: 300,
    },
  } as SxProps,
};
