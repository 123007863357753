import { Dayjs } from "dayjs";
import { IAppointment } from "../../../../services/api/Appointment/types";

export const isUserStoreManager = (userId: string, storeManagerId: string) =>
  userId === storeManagerId;

export const hasDateAppointments = (
  appointments: IAppointment[],
  selectedDate: Dayjs
) =>
  appointments?.some(
    (appointment) =>
      appointment.date.split("T")[0] === selectedDate?.format("YYYY-MM-DD")
  );
