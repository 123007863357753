import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EditIcon from "@mui/icons-material/Edit";
import defaultEmployeeImage from "../../../../../assets/svg/defaultUser.svg";
import {
  createServiceEmployee,
  validateServiceStorePrices,
} from "../usualFunctions";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import CustomCard from "../../../../../components/general/CustomCard";
import serviceEmployeeApi from "../../../../../services/api/ServiceEmployee";
import serviceStoreApi from "../../../../../services/api/ServiceStore";
import { IServiceStore } from "../../../../../services/api/ServiceStore/types";
import { generalDarkGrey } from "../../../../../styles/colors";

import PriceInput from "../../../../../components/general/PriceInput";
import DynamicPriceInput from "../DynamicPriceInput";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import { CURRENT_SUBDOMAIN, getServiceStoresFirebasePath } from "../../../../../utils/stringUtils";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import useFetchEmployeesFromStore from "../../../../../hooks/entities/useFetchEmployeesFromStore";
import { sharedStyles } from "../../../../../styles/shared";
import { WEEKDAY_LABELS } from "../../workingTimeConstants";
import BackPageButton from "../../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../../styles/sharedComponentStyles";
import {
  deleteImageFromFirebase,
  uploadOriginalAndPreviewImagesToFirebase,
} from "../../../../../utils/firebase/functions";
import ImageGallery from "../../../../../components/comercial/ImageGallery";
import { handleDeleteExistingOriginalAndPreviewImageClick } from "../sharedFunctions";
import ImageGalleryContainer from "../../../../../components/comercial/ImageGallery/ImageGalleryContainer";

const EditService = () => {
  const navigate = useNavigate();

  const selectedServiceEmployeeEmployeeIds: string[] = getLocalStorageItem(
    "selectedServiceEmployeeEmployeeIds"
  );

  const selectedServiceStore: IServiceStore = getLocalStorageItem(
    "selectedServiceStore"
  );

  const descriptionRef = useRef<HTMLInputElement>(null);

  const [dynamicPriceAllowed, setDynamicPriceAllowed] = useState(
    !selectedServiceStore.priceDay.every(
      (price) => price === selectedServiceStore.priceDay[0]
    )
  );

  selectedServiceStore.price = selectedServiceStore.price / 100;

  for (let i = 0; i < selectedServiceStore.priceDay?.length; i++)
    selectedServiceStore.priceDay[i] = selectedServiceStore.priceDay[i] / 100;

  const [newImages, setNewImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImageUrls, setOriginalImageUrls] = useState<string[]>(
    selectedServiceStore.imageOriginal
  );
  const [previewImageUrls, setPreviewImageUrls] = useState<string[]>(
    selectedServiceStore.imagePreview
  );

  const { employees } = useFetchEmployeesFromStore(
    selectedServiceStore.storeId
  );

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>(
    selectedServiceEmployeeEmployeeIds
  );

  const [noEmployeesSelected, setNoEmployeesSelected] =
    useState<boolean>(false);

  const [fixedPrice, setFixedPrice] = useState<number | null>(
    Number(selectedServiceStore.price)
  );

  const [sundayPrice, setSundayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[0])
  );
  const [mondayPrice, setMondayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[1])
  );
  const [tuesdayPrice, setTuesdayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[2])
  );
  const [wednesdayPrice, setWednesdayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[3])
  );
  const [thursdayPrice, setThursdayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[4])
  );
  const [fridayPrice, setFridayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[5])
  );
  const [saturdayPrice, setSaturdayPrice] = useState<number | null>(
    Number(selectedServiceStore.priceDay[6])
  );

  const prices = [
    mondayPrice,
    tuesdayPrice,
    wednesdayPrice,
    thursdayPrice,
    fridayPrice,
    saturdayPrice,
    sundayPrice,
  ];

  const setPrices = [
    setMondayPrice,
    setTuesdayPrice,
    setWednesdayPrice,
    setThursdayPrice,
    setFridayPrice,
    setSaturdayPrice,
    setSundayPrice,
  ];

  const [durationOfService, setDurationOfService] = useState<number>(
    selectedServiceStore.durationOfService
  );

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  const checkIfPricesAreValid = () =>
    validateServiceStorePrices(
      fixedPrice,
      mondayPrice,
      tuesdayPrice,
      wednesdayPrice,
      thursdayPrice,
      fridayPrice,
      saturdayPrice,
      sundayPrice,
      dynamicPriceAllowed
    );

  const handleUpdateServiceEmployee = async () => {
    try {
      const serviceEmployeeResponse =
        await serviceEmployeeApi.getServiceEmployeeByServiceStoreId(
          selectedServiceStore.id
        );
      for (let i = 0; i < serviceEmployeeResponse.length; i++)
        await serviceEmployeeApi.deleteServiceEmployee(
          serviceEmployeeResponse[i].id
        );

      for (let i = 0; i < selectedEmployeeIds.length; i++)
        await createServiceEmployee(
          selectedEmployeeIds[i],
          selectedServiceStore.id
        );
    } catch (error) {
      console.warn("Error updating service-employee: ", error);
    }
  };

  /* TODO: refactor the function below */
  const handleServiceAndServiceStoreEdition = async () => {
    if (!submittedOnce) setSubmittedOnce(true);

    if (checkIfPricesAreValid()) {
      const maximumWeekPrice = Math.max(
        mondayPrice,
        tuesdayPrice,
        wednesdayPrice,
        thursdayPrice,
        fridayPrice,
        saturdayPrice,
        sundayPrice
      );

      if (!noEmployeesSelected) {
        setButtonsDisabled(!buttonsDisabled);

        await handleUpdateServiceEmployee();

        try {
          for (let i = 0; i < selectedServiceStore.imageOriginal.length; i++)
            !originalImageUrls.includes(
              selectedServiceStore.imageOriginal[i]
            ) &&
              (await Promise.all([
                deleteImageFromFirebase(selectedServiceStore.imageOriginal[i]),
                deleteImageFromFirebase(selectedServiceStore.imagePreview[i]),
              ]));

          let finalOriginalImages = originalImageUrls;
          let finalPreviewImages = previewImageUrls;

          if (newImages.length > 0) {
            const response = await uploadOriginalAndPreviewImagesToFirebase(
              newImages,
              getServiceStoresFirebasePath(
                selectedServiceStore.Store.name,
                selectedServiceStore.Store.id,
                selectedServiceStore.Service.name,
                selectedServiceStore.id
              )
            );

            finalOriginalImages.push(...response.originalImageUrls);
            finalPreviewImages.push(...response.previewImageUrls);
          }

          const updatedServiceStore = {
            description: descriptionRef?.current?.value,
            price: !dynamicPriceAllowed ? fixedPrice : maximumWeekPrice,
            imageOriginal: originalImageUrls,
            imagePreview: previewImageUrls,
            priceDay: dynamicPriceAllowed
              ? [
                  sundayPrice,
                  mondayPrice,
                  tuesdayPrice,
                  wednesdayPrice,
                  thursdayPrice,
                  fridayPrice,
                  saturdayPrice,
                ]
              : [
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                ],
            durationOfService,
          };

          await serviceStoreApi.updateServiceStore(
            selectedServiceStore.id,
            updatedServiceStore
          );

          window.alert(
            `${selectedServiceStore.Service.name} editado com sucesso!`
          );
          navigate("/services");
        } catch (error) {
          setButtonsDisabled(false);
          console.warn("Error editing a service or services store: ", error);
        }
      }
    }
  };

  const handleToggleDynamicPrices = () =>
    setDynamicPriceAllowed(!dynamicPriceAllowed);

  useEffect(() => {
    setNoEmployeesSelected(!(selectedEmployeeIds?.length > 0));
  }, [selectedEmployeeIds]);

  const handleSelectEmployee = (employeeId: string) =>
    setSelectedEmployeeIds((prevSelectedEmployeeIds) =>
      !selectedEmployeeIds.includes(employeeId)
        ? [...prevSelectedEmployeeIds, employeeId]
        : prevSelectedEmployeeIds.filter((id) => id !== employeeId)
    );

  const renderServiceData = (
    <>
      <ComercialInterfaceHeader title="Dados do serviço" />

      <Box sx={sharedStyles.singleFormContainer}>
        <Box
          sx={{
            ...sharedStyles.singleFormContainer,
            flexDirection: "row",
            "@media(max-width:800px)": { flexDirection: "column" },
          }}
        >
          <Box
            sx={{
              ...sharedStyles.singleFormContainer,
              width: "65%",
              "@media(max-width:800px)": { width: "100%" },
            }}
          >
            <Box
              sx={{ ...sharedStyles.singleFormContainer, flexDirection: "row" }}
            >
              <TextField
                size="small"
                label="Nome do serviço*"
                value={selectedServiceStore?.Service.name}
                fullWidth
                disabled
              />
              <TextField
                size="small"
                label="Duração (horas)"
                type="number"
                autoComplete="off"
                value={durationOfService}
                onChange={(e) => setDurationOfService(Number(e.target.value))}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Box>

            <TextField
              size="small"
              label="Descrição"
              multiline
              rows={4}
              fullWidth
              inputRef={descriptionRef}
              defaultValue={selectedServiceStore?.description}
            />
          </Box>

          <Box
            sx={{
              ...sharedStyles.singleFormContainer,
              width: "35%",
              "@media(max-width:800px)": { width: "100%" },
            }}
          >
            {!dynamicPriceAllowed && (
              <PriceInput
                label="Preço fixo (R$)"
                size="small"
                placeholder="Ex.: R$ 50,00"
                value={fixedPrice.toFixed(2)}
                onChange={(e) => setFixedPrice(Number(e.target.value))}
                error={!checkIfPricesAreValid() && submittedOnce}
                helperText={
                  !checkIfPricesAreValid() && submittedOnce
                    ? "O preço deve ser maior que R$ 0,00"
                    : null
                }
                autoComplete="off"
                inputRef={(input) => {
                  if (input && !checkIfPricesAreValid() && submittedOnce)
                    input.focus();
                }}
              />
            )}

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleToggleDynamicPrices}
                    checked={dynamicPriceAllowed}
                  />
                }
                label="Preço dinâmico"
                componentsProps={{ typography: { fontWeight: 600 } }}
              />
              <Typography textAlign="justify">
                O preço dinâmico possibilita preços diferentes em diferentes
                dias da semana.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  const renderDynamicPrice = (
    <>
      {dynamicPriceAllowed ? (
        <>
          <ComercialInterfaceHeader title="Preço dinâmico (R$)" />
          <Box
            ref={(ref: any) =>
              ref &&
              !checkIfPricesAreValid() &&
              submittedOnce &&
              ref.querySelector('input[aria-invalid="true"]').focus()
            }
            sx={sharedStyles.dynamicPriceInputsContainer}
          >
            {WEEKDAY_LABELS.map((_, index) => (
              <Box key={index} sx={{ maxWidth: "150px" }}>
                <DynamicPriceInput
                  value={prices[index]}
                  setValue={(value) => setPrices[index](value)}
                  weekdayIndex={index}
                  submittedOnce={submittedOnce}
                />
              </Box>
            ))}
          </Box>
        </>
      ) : null}
    </>
  );

  const renderExcursionImageGallery = (
    <>
      <ImageGalleryHeader
        label="Adicionar"
        icon={<AddPhotoAlternateIcon />}
        setImages={setNewImages}
      />
      <ImageGalleryContainer>
        <ImageGallery
          images={originalImageUrls}
          setImages={setOriginalImageUrls}
          removeImageFunction={(index) =>
            handleDeleteExistingOriginalAndPreviewImageClick(
              index,
              setOriginalImageUrls,
              setPreviewImageUrls
            )
          }
          interable
        />
        <ImageGallery images={newImages} setImages={setNewImages} interable />
      </ImageGalleryContainer>
    </>
  );

  const renderEmployeeSelection = (
    <>
      <ComercialInterfaceHeader title="Funcionários para o serviço" />
      <Box sx={sharedStyles.serviceEmployeesGrid}>
        {employees &&
          employees.map((employee) => {
            return (
              <Box
                onClick={() => handleSelectEmployee(employee.id)}
                key={employee.id}
              >
                <CustomCard
                  photoURL={employee.User.imagePreview}
                  defaultPhoto={defaultEmployeeImage}
                  header={""}
                  primaryText={employee.User.name}
                  secondaryText={""}
                  isSelected={!!selectedEmployeeIds?.includes(employee.id)}
                />
              </Box>
            );
          })}
      </Box>
      {noEmployeesSelected && (
        <Typography color="error">
          É necessário selecionar pelo menos um funcionário.
        </Typography>
      )}
    </>
  );

  const renderAddServicePageContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <form
        onSubmit={handleServiceAndServiceStoreEdition}
        style={sharedStyles.form}
      >
        {renderServiceData}
        {renderDynamicPrice}
        {renderExcursionImageGallery}
        {renderEmployeeSelection}
        <Box sx={sharedStyles.dividedFormContainer}>
          <PrimaryButton
            label="Confirmar edição"
            isLoading={buttonsDisabled}
            icon={<EditIcon />}
          />

          <SecondaryButton
            label="Cancelar"
            onClickAction={() => navigate("/services")}
            isDisabled={buttonsDisabled}
          />
        </Box>
      </form>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderAddServicePageContent} />
    </Box>
  );
};

export default EditService;
