import dayjs from "dayjs";
import { DocType } from "../types/types";

export const getDemandoriaSubdomain = () => {
  return window.location.host.split(".")[0];
};

export const shortenLargeTexts = (text: string, limitIndex: number): string => {
  let shortVersion = text;

  if (text.length > limitIndex)
    shortVersion = text.substring(0, limitIndex) + "...";

  return shortVersion;
};

export const CURRENT_SUBDOMAIN = getDemandoriaSubdomain();

export const DEMANDORIA_SUBDOMAINS = ["beleza", "pet", "turismo"];

export const isCPFValid = (docNumber: string) => {
  return docNumber.replace(/[._-]/g, "").length === 11;
};

export const checkDocNumberValidity = (docNumber: string, docType: DocType) => {
  switch (docType) {
    case DocType.CPF:
      return isCPFValid(docNumber);
  }
};

export const formatDateString = (date: string) =>
  dayjs(date).format("DD/MM/YYYY");

export const getUserFirebasePath = (userName: string, userId: string) =>
  `${CURRENT_SUBDOMAIN}/users/${userName}_${userId}`;

export const getStoreImagesFirebasePath = (
  storeName: string,
  storeId: string
) => `${CURRENT_SUBDOMAIN}/stores/${storeName}_${storeId}/store-images`;

export const getServiceStoresFirebasePath = (
  storeName: string,
  storeId: string,
  serviceName: string,
  serviceStoreId: string
) =>
  `${CURRENT_SUBDOMAIN}/stores/${storeName}_${storeId}/services/${serviceName}_${serviceStoreId}`;

export const getExcursionsFirebasePath = (
  storeName: string,
  storeId: string,
  excursionTitle: string,
  excursionId: string
) =>
  `${CURRENT_SUBDOMAIN}/stores/${storeName}_${storeId}/excursions/${excursionTitle}_${excursionId}`;
