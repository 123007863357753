import { Avatar, Box, SxProps, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import TimeRangeInterpreter from "../../../../../components/comercial/TimeRangeInterpreter";
import { IEmployee } from "../../../../../services/api/Employee/types";
import { DocType } from "../../../../../types/types";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { sharedStyles } from "../../../../../styles/shared";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import BackPageButton from "../../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../../styles/sharedComponentStyles";
import ContactIcon from "../../../../../components/general/ContactIcon";

const EmployeeView = () => {
  const selectedEmployee: IEmployee = getLocalStorageItem("selectedEmployee");

  const renderEmployeeData = (
    <>
      <ComercialInterfaceHeader title="Dados do funcionário" />
      <Box sx={sharedStyles.singleFormContainer}>
        <Box
          sx={{
            ...sharedStyles.singleFormContainer,
            gap: "10px",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Avatar
            alt="Imagem do estabelecimento"
            src={selectedEmployee.User.imageOriginal}
            sx={styles.avatar}
          />
          <Typography fontWeight="bold" variant="h6">
            {selectedEmployee.User.name}
          </Typography>
          <Typography textAlign="justify">
            {selectedEmployee.User.docType === DocType.CPF
              ? `${selectedEmployee.User.docNumber} (CPF)`
              : `${selectedEmployee.User.docNumber} (Passaporte)`}
          </Typography>
        </Box>

        <Box sx={styles.singleContact}>
          <ContactIcon
            icon={<FmdGoodOutlinedIcon sx={{ color: "primary.main" }} />}
          />
          <Typography textAlign="justify">{`${
            selectedEmployee.User.logradouro
          }, ${selectedEmployee.User.number || "s/n"} - ${
            selectedEmployee.User.district
          }, ${selectedEmployee.User.city} - ${selectedEmployee.User.state}, ${
            selectedEmployee.User.CEP
          }`}</Typography>
        </Box>

        {selectedEmployee.User.phone && (
          <Box sx={styles.singleContact}>
            <ContactIcon
              icon={<LocalPhoneIcon sx={{ color: "primary.main" }} />}
            />
            <Typography textAlign="justify">
              {selectedEmployee.User.phone}
            </Typography>
          </Box>
        )}

        {selectedEmployee.User.email && (
          <Box sx={styles.singleContact}>
            <ContactIcon
              icon={<AlternateEmailIcon sx={{ color: "primary.main" }} />}
            />
            <Typography textAlign="justify">
              {selectedEmployee.User.email}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );

  const renderWorkScale = (
    <>
      <ComercialInterfaceHeader title="Horários de funcionamento" />
      <Box sx={sharedStyles.singleFormContainer}>
        <TimeRangeInterpreter
          timeMonday={selectedEmployee.timeMonday}
          timeTuesday={selectedEmployee.timeTuesday}
          timeWednesday={selectedEmployee.timeWednesday}
          timeThursday={selectedEmployee.timeThursday}
          timeFriday={selectedEmployee.timeFriday}
          timeSaturday={selectedEmployee.timeSaturday}
          timeSunday={selectedEmployee.timeSunday}
          onChange={function (
            week: Array<{ day: string; hours: string[] }>
          ): void {
            throw new Error("Function not implemented.");
          }}
        />
      </Box>
    </>
  );

  const renderAddStorePageContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <form style={sharedStyles.form}>
        {renderEmployeeData}
        {renderWorkScale}
      </form>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default EmployeeView;

const styles = {
  avatar: { width: "150px", height: "150px" } as SxProps,

  singleContact: { display: "flex", alignItems: "center", gap: 1 } as SxProps,
};
