import dayjs from "dayjs";
import appointmentApi from "../../../services/api/Appointment";
import { AppointmentStatus } from "../../../types/types";

export const handleFinishAppointmentClick = async (appointmentId: string) => {
  const updatedAppointment = {
    status: AppointmentStatus.FINISHED,
  };

  try {
    await appointmentApi.updateAppointment(appointmentId, updatedAppointment);
  } catch (error) {
    console.warn("Error finishing appointment: ", error);
  }
};

export const handleCancelAppointmentClick = async (
  cancellationReason: string,
  appointmentId: string,
  serviceEmployeeId: string
) => {
  const today = dayjs().toISOString();

  const updatedAppointment = {
    serviceEmployeeId: serviceEmployeeId,
    cancelationReason: cancellationReason,
    canceledAt: today,
    status: AppointmentStatus.CANCELED,
  };

  try {
    await appointmentApi.updateAppointment(
      appointmentId,
      updatedAppointment
    );

    location.reload();
  } catch (error) {
    console.warn("Error cancelling the appointment: ", error);
  }
};
