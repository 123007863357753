import { initializeApp } from "firebase/app";
import {
  getStorage,
} from "firebase/storage";

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCjw4Nazp3CebomQ7G11rG8jiEyPr7M5Aw",
  authDomain: "demandoria-auth.firebaseapp.com",
  projectId: "demandoria-auth",
  storageBucket: "demandoria-auth.appspot.com",
  messagingSenderId: "729888061740",
  appId: "1:729888061740:web:a9c2d49359657f4de65489",
};

export const app = initializeApp(FIREBASE_CONFIG);
export const storage = getStorage(app);


