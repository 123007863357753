import { useEffect, useState } from "react";
import { IService } from "../../services/api/Service/types";
import { fetchServicesForAutocomplete } from "../../utils/fetchEntities";

const useFetchSearchServices = (search: string) => {
  const [services, setServices] = useState<IService[]>([]);

  useEffect(() => {
    search.length > 0 &&
      fetchServicesForAutocomplete(search).then((response) =>
        setServices(response)
      );
  }, [search]);

  return { services };
};

export default useFetchSearchServices;
