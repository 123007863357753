import { setLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import { userApi } from "../../../../../services";
import serviceEmployeeApi from "../../../../../services/api/ServiceEmployee";
import serviceStoreApi from "../../../../../services/api/ServiceStore";
import { IServiceStore } from "../../../../../services/api/ServiceStore/types";
import { IUser } from "../../../../../services/api/User/types";
import { clearFirebaseFolder } from "../../../../../utils/firebase/functions";
import { CURRENT_SUBDOMAIN } from "../../../../../utils/stringUtils";

/* TODO: refactor all functions in order to improve clean code and performance */

const handleSelectServiceStore = (serviceStore: IServiceStore) =>
  setLocalStorageItem("selectedServiceStore", serviceStore);

export const handleServiceStoreDetailsClick = async (
  serviceStore: IServiceStore
) => {
  handleSelectServiceStore(serviceStore);

  try {
    const serviceEmployeeResponse =
      await serviceEmployeeApi.getServiceEmployeeByServiceStoreId(
        serviceStore.id
      );

    const serviceEmployeeUsers: IUser[] = [];

    let iterableUser = null;
    for (let i = 0; i < serviceEmployeeResponse.length; i++) {
      iterableUser = await userApi.getUser(
        serviceEmployeeResponse[i].Employee.userId
      );
      serviceEmployeeUsers.push(iterableUser);
    }

    setLocalStorageItem("selectedServiceEmployeeUsers", serviceEmployeeUsers);
  } catch (error) {
    console.warn("Error fetching service-employees: ", error);
  }
};

export const handleEditServiceStoreClick = async (
  serviceStore: IServiceStore
) => {
  handleSelectServiceStore(serviceStore);

  try {
    const serviceEmployeeResponse =
      await serviceEmployeeApi.getServiceEmployeeByServiceStoreId(
        serviceStore.id
      );

    const serviceEmployeeEmployeeIds: string[] = [];

    let iterableEmployee = null;
    for (let i = 0; i < serviceEmployeeResponse.length; i++) {
      iterableEmployee = serviceEmployeeResponse[i].employeeId;
      serviceEmployeeEmployeeIds.push(iterableEmployee);
    }

    setLocalStorageItem(
      "selectedServiceEmployeeEmployeeIds",
      serviceEmployeeEmployeeIds
    );
  } catch (error) {
    console.warn("Error fetching service-employee: ", error);
  }
};

export const handleDeleteServiceStore = async (
  serviceStore: IServiceStore,
  reloadFunction: VoidFunction
) => {
  if (
    window.confirm(
      `Tem certeza que deseja excluir ${serviceStore.Service.name}?`
    )
  ) {
    try {
      const serviceDeletionPromises = [];

      if (serviceStore.imageOriginal.length > 0)
        serviceDeletionPromises.push(
          clearFirebaseFolder(
            `${CURRENT_SUBDOMAIN}/stores/${serviceStore.Store.name}_${serviceStore.Store.id}/services/${serviceStore.Service.name}_${serviceStore.id}`
          )
        );

      serviceDeletionPromises.push(
        serviceStoreApi.deleteServiceStore(serviceStore.id)
      );

      await Promise.all(serviceDeletionPromises);

      reloadFunction();
    } catch (error) {
      console.error("Error deleting the service-store: ", error);
    }
  }
};
