import { SxProps } from "@mui/material";
import { CSSProperties } from "react";

export const sharedStyles = {
  sharedMainContainer: {
    overflowX: "hidden",
    height: "100vh",
    pb: "40px",
  } as SxProps,

  tableAvatarStyle: {
    width: "60px",
    height: "60px",
  } as SxProps,

  storeItemContent: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  } as SxProps,

  form: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
  } as CSSProperties,

  singleFormContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  } as SxProps,

  dividedFormContainer: {
    display: "flex",
    gap: "20px",

    "@media(max-width:800px)": { flexDirection: "column" },
  } as SxProps,

  dynamicPriceInputsContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "10px",

    "@media(max-width:600px)": {
      flexDirection: "column",
    },
  } as SxProps,

  serviceEmployeesGrid: {
    display: "flex",
    overflowX: "auto",
    whiteSpace: "nowrap",
    width: "100%",
    p: "10px 0px",
    gap: "20px",
  } as SxProps,

  appointmentsList: {
    overflowY: "auto",
    boxShadow:
      "inset 0px 15px 10px -10px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -10px rgba(0, 0, 0, 0.05)",
    "@media (max-width: 900px)": {
      width: "100%",
    },
    borderRadius: "10px",
  } as SxProps,
};
