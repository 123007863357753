import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import ClientIcon from "../../../../assets/img/cuttedHair.png";
import SalonIcon from "../../../../assets/img/salon.png";
import styles from "./styles";
import Footer from "../../../../components/general/Footer";

const BeautyLandingPage = () => {
  const navigate = useNavigate();

  const firstSection = (
    <section style={styles.firstSectionContainer}>
      <Box sx={styles.firstSectionHeader}>
        <Box sx={{ marginLeft: "15px" }}>
          <Typography variant="h5" fontWeight="bold">
            Demandoria Beleza
          </Typography>
        </Box>
        <Box sx={styles.alreadyRegisteredContainer}>
          <Typography sx={styles.alreadyRegisteredLabel}>
            Já possui conta?
          </Typography>
          <Button
            sx={styles.loginButton}
            disableElevation
            variant="outlined"
            onClick={() => navigate("/login")}
          >
            Realizar login
          </Button>
        </Box>
      </Box>
      <Box sx={styles.presentationContainer}>
        <Typography variant="h4" fontWeight="bold">
          Sua melhor versão com o
          <Typography
            sx={{ color: "secondary.main", marginLeft: "8px" }}
            variant="h4"
            fontWeight="bold"
          >
            Demandoria Beleza
          </Typography>
        </Typography>
        <Typography variant="h6" fontWeight="regular">
          Transforme-se hoje! Experimente nossos serviços exclusivos e brilhe
          como nunca.
        </Typography>

        <Button
          sx={styles.registerButton}
          disableElevation
          variant="contained"
          startIcon={<LoginIcon />}
          onClick={() => navigate("/register")}
        >
          Cadastrar-se
        </Button>
      </Box>
    </section>
  );

  const secondSection = (
    <section style={styles.secondSectionContainer}>
      <Box sx={styles.secondSectionHeader}>
        <Typography variant="h5" fontWeight="bold">
          Para clientes ou profissionais de beleza
        </Typography>
        <Typography>Cada necessidade, uma conta personalizada</Typography>
      </Box>
      <Box sx={styles.roleCardContainer}>
        <Box sx={styles.roleCard}>
          <img
            src={ClientIcon}
            alt="Client icon"
            style={styles.roleCardImage}
          />
          <Typography variant="h6" fontWeight="bold">
            Cliente
          </Typography>
          <Typography>
            Agende serviços sob demanda para cuidar da sua autoestima
          </Typography>
        </Box>
        <Box sx={styles.roleCard}>
          <img
            src={SalonIcon}
            alt="Pet Manager icon"
            style={styles.roleCardImage}
          />
          <Typography variant="h6" fontWeight="bold">
            Comercial
          </Typography>
          <Typography>
            Cadastre e administre seus estabelecimentos, serviços e funcionários
          </Typography>
        </Box>
      </Box>
    </section>
  );

  return (
    <>
      {/* <Helmet>
        <title>
          Demandoria | Agende os melhores serviços sem sair de casa!
        </title>
        <meta
          name="description"
          content="Revolucione sua forma de agendar serviços com o Demandoria! Nós oferecemos um agendamento rápido e prático para uma variedade de serviços. Encontre e reserve os melhores profissionais, tudo isso sem sair de casa. Experimente hoje mesmo."
        />
        <meta
          name="keywords"
          content="ecommerce, services, schedule, agendamento de serviços, agendar online, demandoria, serviços, marketplace"
        />
      </Helmet> */}
      <Box width="100%" height="100%">
        {firstSection}
        {/* {secondSection} */}
        <Footer />
      </Box>
    </>
  );
};

export default BeautyLandingPage;
