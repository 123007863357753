import {
  Box,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Avatar,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import defaultStoreImage from "../../../../assets/svg/defaultStore.svg";
import { cleanLocalStorage } from "../../../../utils/cleanLocalStorage";
import ComercialDrawer from "../../../../components/pet-beauty/CormercialDrawer";
import ComercialInterfaceHeader from "../../../../components/comercial/ComercialInterfaceHeader";
import { IEmployee } from "../../../../services/api/Employee/types";
import { IStore } from "../../../../services/api/Store/types";
import { setLocalStorageItem } from "../../../../hooks/localStorageHooks";
import useFetchUserEmployees from "../../../../hooks/entities/useFetchUserEmployedStores";
import { sharedStyles } from "../../../../styles/shared";
import ComercialTable from "../../../../components/comercial/ComercialTable";
import { backButtonContainer } from "../../../../styles/sharedComponentStyles";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";

const MyWorkscales = () => {
  const navigate = useNavigate();

  cleanLocalStorage();

  const { userEmployees, loading } = useFetchUserEmployees();

  const handleSelectEmployee = (employee: IEmployee) =>
    setLocalStorageItem("selectedEmployee", employee);

  const handleEditEmployeeClick = (employee: IEmployee, store: IStore) => {
    handleSelectEmployee(employee);
    setLocalStorageItem("selectedStore", store);
    navigate("/employees/edit");
  };

  const storesTableHeader = (
    <TableRow>
      <TableCell align="left">Estabelecimento</TableCell>
      <TableCell align="right">Ações</TableCell>
    </TableRow>
  );

  const storesTableBody = (
    <>
      {userEmployees?.length > 0 ? (
        userEmployees?.map((employee, index) => (
          <TableRow
            key={employee.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell
              component="th"
              sx={{ ...sharedStyles.storeItemContent }}
              align="left"
            >
              <Avatar
                src={employee.store.imagePreview[0] ?? defaultStoreImage}
                sx={sharedStyles.tableAvatarStyle}
              />

              <ListItemText
                primary={employee.store?.name}
                secondary={employee.store?.CNPJ}
                primaryTypographyProps={{ fontWeight: 500 }}
              />
            </TableCell>

            <TableCell align="right">
              <Tooltip title="Editar escala de trabalho">
                <IconButton
                  color="primary"
                  onClick={() =>
                    handleEditEmployeeClick(
                      userEmployees[index],
                      employee.store
                    )
                  }
                >
                  <EditCalendarIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell>
            <Typography color="text.secondary" variant="body2">
              Ainda não há estabelecimentos cadastrados
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );

  const renderStoresPageContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <ComercialInterfaceHeader title="Estabelecimentos em que você trabalha" />
      <Box width="100%">
        {!loading ? (
          <ComercialTable header={storesTableHeader} body={storesTableBody} />
        ) : (
          <Box sx={styles.loadingContainer}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderStoresPageContent} />
    </Box>
  );
};

export default MyWorkscales;

const styles = {
  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
