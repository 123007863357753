import { useEffect, useState } from "react";
import { IEmployee } from "../../services/api/Employee/types";
import { fetchEmployeesFromAStore } from "../../utils/fetchEntities";

const useFetchEmployeesFromStore = (storeId: string | null) => {
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [employeesLoading, setEmployeesLoading] = useState(false);

  const fetchEmployees = () => {
    if (!storeId) return;

    setEmployeesLoading(true);
    fetchEmployeesFromAStore(storeId)
      .then((response) => setEmployees(response))
      .catch((error) => {
        console.error("Error fetching employees:", error);
        setEmployees([]);
      })
      .finally(() => setEmployeesLoading(false));
  };

  useEffect(() => {
    fetchEmployees();
  }, [storeId]);

  return { employees, employeesLoading, fetchEmployees };
};

export default useFetchEmployeesFromStore;
