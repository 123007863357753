import {
  Box,
  CircularProgress,
  IconButton,
  ListItemText,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { cleanLocalStorage } from "../../../../../utils/cleanLocalStorage";
import employeeApi from "../../../../../services/api/Employee";
import { IEmployee } from "../../../../../services/api/Employee/types";
import { IStore } from "../../../../../services/api/Store/types";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import useManagerStores from "../../../../../hooks/context-hooks/useManagerStores";
import useFetchManagerStores from "../../../../../hooks/entities/useFetchManagerStores";
import { sharedStyles } from "../../../../../styles/shared";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import ComercialTable from "../../../../../components/comercial/ComercialTable";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { setLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import useFetchEmployeesFromStore from "../../../../../hooks/entities/useFetchEmployeesFromStore";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { useAuth } from "../../../../../context/AuthProvider/useAuth";

const Employees = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  cleanLocalStorage();

  const managerStoresContext = useManagerStores();
  const { stores } = managerStoresContext;
  const { loadingManagerStores } = useFetchManagerStores();

  const [selectedStoreIndex, setSelectedStoreIndex] = useState(0);

  const { employees, employeesLoading, fetchEmployees } =
    useFetchEmployeesFromStore(stores && stores[selectedStoreIndex]?.id);

  const isMobile = useMediaQuery("(max-width:800px)");

  const handleSelectEmployee = (employee: IEmployee) =>
    setLocalStorageItem("selectedEmployee", employee);

  const handleSelectStore = (store: IStore) =>
    setLocalStorageItem("selectedStore", store);

  const handleAddEmployeeClick = (store: IStore) => {
    handleSelectStore(store);
    navigate(`/employees/add?storeId=${store.id}`);
  };

  const handleEmployeeDetailsClick = (employee: IEmployee) => {
    handleSelectEmployee(employee);
    navigate(`/employees/details?id=${employee.id}`);
  };

  const handleEditEmployeeClick = (employee: IEmployee, store: IStore) => {
    handleSelectEmployee(employee);
    handleSelectStore(store);
    navigate("/employees/edit");
  };

  const handleDeleteEmployee = async (employee: IEmployee) => {
    const { id } = employee;

    if (
      window.confirm(`Tem certeza que deseja excluir ${employee.User.name}?`)
    ) {
      try {
        await employeeApi.deleteEmployee(id);
        console.log(`Employee ${id} deleted with success.`);
        fetchEmployees();
      } catch (error) {
        console.error("Error deleting the employee: ", error);
      }
    }
  };

  const filteredEmployees = employees.filter(
    (employee) => employee.User.id !== auth.user.id
  );

  const employeesTableHeader = (
    <>
      {!employeesLoading && employees && (
        <TableRow>
          <TableCell align="left">Funcionário</TableCell>
          <TableCell align="right">Ações</TableCell>
        </TableRow>
      )}
    </>
  );

  const employeesTableBody = (
    <>
      {!employeesLoading ? (
        <>
          {filteredEmployees.length > 0 ? (
            <>
              {filteredEmployees.map(
                (employee) =>
                  employee.User.id !== auth.user.id && (
                    <TableRow
                      key={employee.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        sx={{ ...sharedStyles.storeItemContent }}
                        align="left"
                      >
                        <Avatar
                          src={employee?.User.imagePreview}
                          sx={sharedStyles.tableAvatarStyle}
                        />

                        <ListItemText
                          primary={employee?.User.name}
                          secondary={"Funcionário"}
                          primaryTypographyProps={{
                            fontWeight: 500,
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Detalhes">
                          <IconButton
                            color="primary"
                            onClick={() => handleEmployeeDetailsClick(employee)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Editar escala de trabalho">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleEditEmployeeClick(
                                employee,
                                stores[selectedStoreIndex]
                              )
                            }
                          >
                            <EditCalendarIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Desvincular">
                          <IconButton
                            color="primary"
                            onClick={() => handleDeleteEmployee(employee)}
                          >
                            <LinkOffIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
              )}
            </>
          ) : (
            <TableRow>
              <TableCell>
                <Typography color="text.secondary" variant="body2">
                  Não há funcionários vinculados a este estabelecimento
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </>
      ) : (
        <TableRow>
          <TableCell align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      )}
    </>
  );

  const renderEmployeePageContent = (
    <Box>
      {!loadingManagerStores ? (
        <Box sx={styles.container}>
          {stores && stores.length > 0 ? (
            <>
              <ComercialInterfaceHeader
                title={`Funcionários de ${
                  stores ? stores[selectedStoreIndex].name : "Carregando..."
                }`}
              />

              <Box>
                <Box sx={styles.filterAndAddContainer}>
                  <Box sx={{ maxWidth: "150px", width: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel>Estabelecimento</InputLabel>
                      <Select
                        label="Estabelecimento"
                        value={stores && selectedStoreIndex}
                        onChange={(e: any) =>
                          setSelectedStoreIndex(e.target.value as number)
                        }
                        size="small"
                      >
                        {stores &&
                          stores.map((store, index) => (
                            <MenuItem value={index} key={index}>
                              {store?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    {!isMobile ? (
                      <PrimaryButton
                        label="Vincular funcionário"
                        onClickAction={() =>
                          handleAddEmployeeClick(stores[selectedStoreIndex])
                        }
                        icon={<LinkIcon />}
                      />
                    ) : (
                      <Button
                        onClick={() =>
                          handleAddEmployeeClick(stores[selectedStoreIndex])
                        }
                        color="primary"
                        variant="contained"
                        disableElevation
                      >
                        <LinkIcon />
                      </Button>
                    )}
                  </Box>
                </Box>

                <Box>
                  <ComercialTable
                    header={employeesTableHeader}
                    body={employeesTableBody}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Typography textAlign="justify">
              Para cadastrar funcionários é necessário{" "}
              <Typography
                color="primary"
                fontWeight="bold"
                onClick={() => navigate("/stores/add")}
                sx={{ cursor: "pointer" }}
                variant="span"
              >
                cadastrar um estabelecimento
              </Typography>
              .
            </Typography>
          )}
        </Box>
      ) : (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderEmployeePageContent} />
    </Box>
  );
};

export default Employees;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  } as SxProps,

  avatarStyle: {
    width: "60px",
    height: "60px",
  },

  filterAndAddContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "50px",
    alignItems: "center",
  } as SxProps,

  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
