import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  ListItemText,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useNavigate } from "react-router-dom";
import ComercialDrawer from "../../../../../components/turism/ComercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import { type IStore } from "../../../../../services/api/Store/types";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import defaultStoreImage from "../../../../../assets/svg/defaultStore.svg";
import ComercialTable from "../../../../../components/comercial/ComercialTable";
import { sharedStyles } from "../../../../../styles/shared";

import VisibilityIcon from "@mui/icons-material/Visibility";
import useManagerStores from "../../../../../hooks/context-hooks/useManagerStores";
import useFetchManagerStores from "../../../../../hooks/entities/useFetchManagerStores";
import { handleDeleteStore } from "../../../../petAndBeauty/Comercial/StoresPages/Stores/functions";
import { handleSelectStore } from "./functions";

const Agencies = () => {
  const navigate = useNavigate();

  const managerStoresContext = useManagerStores();
  const { fetchStores, loadingManagerStores } = useFetchManagerStores();

  const handleSeeStoreDetailsClick = (store: IStore) => {
    handleSelectStore(store);
    navigate(`/agencies/details?id=${store.id}`);
  };

  const handleEditStoreClick = (store: IStore) => {
    handleSelectStore(store);
    navigate(`/agencies/edit?id=${store.id}`);
  };

  const handleDeleteManagerStore = (store: IStore) =>
    handleDeleteStore(store).then(() => fetchStores());

  const storesTableHeader = (
    <TableRow>
      <TableCell align="left">Estabelecimento</TableCell>
      <TableCell align="right">Ações</TableCell>
    </TableRow>
  );

  const storesTableBody = (
    <>
      {managerStoresContext.stores?.length !== 0 ? (
        managerStoresContext.stores?.map((store) => (
          <TableRow
            key={store.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell
              component="th"
              sx={{ ...sharedStyles.storeItemContent }}
              align="left"
            >
              <Avatar
                src={store.imagePreview[0] ?? defaultStoreImage}
                sx={sharedStyles.tableAvatarStyle}
              />

              <ListItemText
                primary={store.name}
                secondary={store?.CNPJ}
                primaryTypographyProps={{ fontWeight: 500 }}
              />
            </TableCell>

            <TableCell align="right">
              <Tooltip title="Detalhes">
                <IconButton
                  color="primary"
                  onClick={() => handleSeeStoreDetailsClick(store)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  onClick={() => handleEditStoreClick(store)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Excluir">
                <IconButton
                  color="primary"
                  onClick={() => handleDeleteManagerStore(store)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell>
            <Typography color="text.secondary" variant="body2">
              Ainda não há agências cadastrados
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );

  const renderStoresPageContent = (
    <Box>
      <ComercialInterfaceHeader
        title="Suas agências"
        actionNode={
          <Button
            onClick={() => navigate("/agencies/add")}
            color="primary"
            variant="contained"
            disableElevation
          >
            <AddBusinessIcon />
          </Button>
        }
      />

      <Box width="100%">
        {!loadingManagerStores ? (
          <ComercialTable header={storesTableHeader} body={storesTableBody} />
        ) : (
          <Box sx={styles.loadingContainer}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderStoresPageContent} />
    </Box>
  );
};

export default Agencies;

const styles = {
  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
