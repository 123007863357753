import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultEmployeeImage from "../../../../../assets/svg/defaultUser.svg";
import AddIcon from "@mui/icons-material/Add";
import {
  createServiceEmployee,
  validateServiceStorePrices,
} from "../usualFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import ImageGallery from "../../../../../components/comercial/ImageGallery";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import CustomCard from "../../../../../components/general/CustomCard";
import serviceApi from "../../../../../services/api/Service";
import { IService } from "../../../../../services/api/Service/types";
import serviceStoreApi from "../../../../../services/api/ServiceStore";
import { IStore } from "../../../../../services/api/Store/types";
import { IServiceStore } from "../../../../../services/api/ServiceStore/types";
import PriceInput from "../../../../../components/general/PriceInput";
import { addServiceSchema } from "../../../../../utils/yupSchemas";
import DynamicPriceInput from "../DynamicPriceInput";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import {
  CURRENT_SUBDOMAIN,
  getServiceStoresFirebasePath,
} from "../../../../../utils/stringUtils";
import { sharedStyles } from "../../../../../styles/shared";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import { WEEKDAY_LABELS } from "../../workingTimeConstants";
import useFetchEmployeesFromStore from "../../../../../hooks/entities/useFetchEmployeesFromStore";
import useFetchSearchServices from "../../../../../hooks/entities/useFetchSearchServices";
import { backButtonContainer } from "../../../../../styles/sharedComponentStyles";
import BackPageButton from "../../../../../components/general/buttons/BackPageButton";
import { uploadOriginalAndPreviewImagesToFirebase } from "../../../../../utils/firebase/functions";
import ImageGalleryContainer from "../../../../../components/comercial/ImageGallery/ImageGalleryContainer";

const AddService = () => {
  const navigate = useNavigate();

  const selectedStore: IStore = getLocalStorageItem("selectedStore");

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addServiceSchema) });

  const [serviceName, setServiceName] = useState<string>("");
  const { services } = useFetchSearchServices(serviceName);

  const [durationOfService, setDurationOfService] = useState<number>(1);

  const descriptionRef = useRef<HTMLInputElement>(null);

  const [dynamicPriceAllowed, setIsDynamicPriceAllowed] = useState(false);
  const [fixedPrice, setFixedPrice] = useState<number | null>(null);

  const [mondayPrice, setMondayPrice] = useState<number | null>(null);
  const [tuesdayPrice, setTuesdayPrice] = useState<number | null>(null);
  const [wednesdayPrice, setWednesdayPrice] = useState<number | null>(null);
  const [thursdayPrice, setThursdayPrice] = useState<number | null>(null);
  const [fridayPrice, setFridayPrice] = useState<number | null>(null);
  const [saturdayPrice, setSaturdayPrice] = useState<number | null>(null);
  const [sundayPrice, setSundayPrice] = useState<number | null>(null);

  const prices = [
    mondayPrice,
    tuesdayPrice,
    wednesdayPrice,
    thursdayPrice,
    fridayPrice,
    saturdayPrice,
    sundayPrice,
  ];

  const setPrices = [
    setMondayPrice,
    setTuesdayPrice,
    setWednesdayPrice,
    setThursdayPrice,
    setFridayPrice,
    setSaturdayPrice,
    setSundayPrice,
  ];

  const [images, setImages] = useState<HTMLCanvasElement[]>([]);

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);

  const [noEmployeesSelected, setNoEmployeesSelected] =
    useState<boolean>(false);

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  const [generalErrorMessage, setGeneralErrorMessage] = useState("");

  const { employees } = useFetchEmployeesFromStore(selectedStore.id);

  const checkIfPricesAreValid = () =>
    validateServiceStorePrices(
      fixedPrice,
      mondayPrice,
      tuesdayPrice,
      wednesdayPrice,
      thursdayPrice,
      fridayPrice,
      saturdayPrice,
      sundayPrice,
      dynamicPriceAllowed
    );

  /* TODO: refactor the function below */
  const handleServiceAndServiceStoreCreation = async () => {
    if (serviceName.length > 0 && checkIfPricesAreValid()) {
      const maximumWeekPrice = Math.max(
        mondayPrice,
        tuesdayPrice,
        wednesdayPrice,
        thursdayPrice,
        fridayPrice,
        saturdayPrice,
        sundayPrice
      );

      if (!noEmployeesSelected) {
        setButtonsDisabled(!buttonsDisabled);

        try {
          let serviceId = "";

          if (services.length === 0) {
            const createdServiceResponse = await serviceApi.createService({
              name: serviceName,
            } as IService);

            serviceId = createdServiceResponse.id;
          } else serviceId = services[0].id;

          const newServiceStore: IServiceStore = {
            serviceId,
            storeId: selectedStore.id,
            description: descriptionRef?.current?.value,
            price: !dynamicPriceAllowed ? fixedPrice : maximumWeekPrice,
            imageOriginal: [],
            imagePreview: [],
            priceDay: dynamicPriceAllowed
              ? prices
              : [
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                  fixedPrice,
                ],
            durationOfService,
          };

          const createdServiceStoreResponse =
            await serviceStoreApi.createServiceStore(newServiceStore);

          selectedEmployeeIds.forEach(
            async (employeeId) =>
              await createServiceEmployee(
                employeeId,
                createdServiceStoreResponse.id
              )
          );

          if (images.length > 0) {
            const { originalImageUrls, previewImageUrls } =
              await uploadOriginalAndPreviewImagesToFirebase(
                images,
                getServiceStoresFirebasePath(
                  selectedStore.name,
                  selectedStore.id,
                  serviceName,
                  createdServiceStoreResponse.id
                )
              );

            newServiceStore.imageOriginal = originalImageUrls;
            newServiceStore.imagePreview = previewImageUrls;

            delete newServiceStore.serviceId;
            delete newServiceStore.storeId;

            await serviceStoreApi.updateServiceStore(
              createdServiceStoreResponse.id,
              newServiceStore
            );
          }

          window.alert(`Serviço adicionado com sucesso!`);
          navigate("/services");
        } catch (error: any) {
          console.error("Error creating a service or services store: ", error);
          setGeneralErrorMessage(error.response.data.message);
        }
        setButtonsDisabled(false);
      }
    }
  };

  const handleSelectEmployee = (employeeId: string) =>
    setSelectedEmployeeIds((prevSelectedEmployeeIds) =>
      !selectedEmployeeIds.includes(employeeId)
        ? [...prevSelectedEmployeeIds, employeeId]
        : prevSelectedEmployeeIds.filter((id) => id !== employeeId)
    );

  const handleToggleDynamicPrices = () =>
    setIsDynamicPriceAllowed(!dynamicPriceAllowed);

  useEffect(() => {
    setNoEmployeesSelected(selectedEmployeeIds.length === 0);
  }, [selectedEmployeeIds]);

  useEffect(() => {
    setGeneralErrorMessage("");
  }, [serviceName]);

  const renderServiceData = (
    <>
      <ComercialInterfaceHeader title="Preencha os dados do serviço" />
      <Box sx={sharedStyles.singleFormContainer}>
        <Box
          sx={{
            ...sharedStyles.singleFormContainer,
            flexDirection: "row",
            "@media(max-width:800px)": { flexDirection: "column" },
          }}
        >
          <Box
            sx={{
              ...sharedStyles.singleFormContainer,
              width: "65%",
              "@media(max-width:800px)": { width: "100%" },
            }}
          >
            <Box
              sx={{ ...sharedStyles.singleFormContainer, flexDirection: "row" }}
            >
              <Autocomplete
                freeSolo
                options={services.map((service) => service.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nome do serviço * "
                    size="small"
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
                fullWidth
                onInputChange={(_, value) => setServiceName(value)}
              />
              <TextField
                size="small"
                label="Duração (horas)"
                type="number"
                autoComplete="off"
                value={durationOfService}
                onChange={(e) => setDurationOfService(Number(e.target.value))}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Box>

            <TextField
              size="small"
              label="Descrição"
              multiline
              rows={4}
              fullWidth
              inputRef={descriptionRef}
              placeholder="Descreva brevemente seu serviço..."
            />
          </Box>

          <Box
            sx={{
              ...sharedStyles.singleFormContainer,
              width: "35%",
              "@media(max-width:800px)": { width: "100%" },
            }}
          >
            {!dynamicPriceAllowed && (
              <PriceInput
                label="Preço fixo (R$)"
                size="small"
                placeholder="Ex.: R$ 50,00"
                value={fixedPrice}
                onChange={(e) => setFixedPrice(Number(e.target.value))}
                error={!checkIfPricesAreValid() && submittedOnce}
                helperText={
                  !checkIfPricesAreValid() &&
                  submittedOnce &&
                  "O preço deve ser maior que R$ 0,00"
                }
                autoComplete="off"
                inputRef={(input) =>
                  input &&
                  !checkIfPricesAreValid() &&
                  submittedOnce &&
                  input.focus()
                }
                fullWidth
              />
            )}

            <Box>
              <FormControlLabel
                control={<Checkbox onChange={handleToggleDynamicPrices} />}
                label="Preço dinâmico"
                componentsProps={{ typography: { fontWeight: 600 } }}
              />
              <Typography textAlign="justify">
                O preço dinâmico possibilita preços diferentes a cada dia da
                semana.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  const renderDynamicPrice = (
    <>
      {dynamicPriceAllowed ? (
        <>
          <ComercialInterfaceHeader title="Preço dinâmico (R$)" />
          <Box
            ref={(ref: any) =>
              ref &&
              !checkIfPricesAreValid() &&
              submittedOnce &&
              ref.querySelector('input[aria-invalid="true"]').focus()
            }
            sx={sharedStyles.dynamicPriceInputsContainer}
          >
            {WEEKDAY_LABELS.map((_, index) => (
              <Box key={index} sx={{ maxWidth: "150px" }}>
                <DynamicPriceInput
                  value={prices[index]}
                  setValue={(value) => setPrices[index](value)}
                  weekdayIndex={index}
                  submittedOnce={submittedOnce}
                />
              </Box>
            ))}
          </Box>
        </>
      ) : null}
    </>
  );

  const renderExcursionImageGallery = (
    <>
      <ImageGalleryHeader
        label="Adicionar"
        icon={<AddPhotoAlternateIcon />}
        setImages={setImages}
      />
      <ImageGalleryContainer>
        <ImageGallery images={images} setImages={setImages} interable />
      </ImageGalleryContainer>
    </>
  );

  const renderEmployeeSelection = (
    <>
      <ComercialInterfaceHeader title="Selecione funcionário(s) para o serviço" />

      <Box sx={sharedStyles.serviceEmployeesGrid}>
        {employees &&
          employees.map((employee) => (
            <Box
              onClick={() => handleSelectEmployee(employee.id)}
              key={employee.id}
            >
              <CustomCard
                photoURL={employee.User.imagePreview}
                defaultPhoto={defaultEmployeeImage}
                header={""}
                primaryText={employee.User.name}
                secondaryText={""}
                isSelected={!!selectedEmployeeIds.includes(employee.id)}
              />
            </Box>
          ))}
      </Box>
      {noEmployeesSelected && (
        <Typography color="error">
          É necessário selecionar pelo menos um funcionário.
        </Typography>
      )}
    </>
  );

  const renderAddServicePageContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <form
        onSubmit={onSubmit(handleServiceAndServiceStoreCreation)}
        style={sharedStyles.form}
      >
        {renderServiceData}
        {renderDynamicPrice}
        {renderExcursionImageGallery}
        {renderEmployeeSelection}

        {generalErrorMessage !== "" && (
          <Alert severity="error">{generalErrorMessage}</Alert>
        )}

        <Box sx={sharedStyles.dividedFormContainer}>
          <PrimaryButton
            label="Confirmar adição"
            isLoading={buttonsDisabled}
            icon={<AddIcon />}
            onClickAction={() => !submittedOnce && setSubmittedOnce(true)}
          />

          <SecondaryButton
            label="Cancelar"
            onClickAction={() => navigate("/services")}
            isDisabled={buttonsDisabled}
          />
        </Box>
      </form>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderAddServicePageContent} />
    </Box>
  );
};

export default AddService;
