import { useEffect, useState } from "react";
import { DocType } from "../../types/types";
import { IUser } from "../../services/api/User/types";
import { userApi } from "../../services";
import { checkDocNumberValidity } from "../../utils/stringUtils";

const useSearchUserByDocNumber = (docType: DocType) => {
  const [docNumber, setDocNumber] = useState("");
  const [searchingUser, setSearchingUser] = useState<boolean>(false);

  const [foundedUser, setFoundedUser] = useState<IUser | null>(null);

  const handleSearchUserByDocNumber = async () => {
    setSearchingUser(true);

    try {
      const userResponse = await userApi.getUserByDocNumber(docNumber);
      setFoundedUser(userResponse);
    } catch (error) {
      alert("Usuário não encontrado!");
      console.warn(
        "Error searching for a user by the document number: ",
        error
      );
    }

    setSearchingUser(false);
  };

  useEffect(() => {
    checkDocNumberValidity(docNumber, docType)
      ? handleSearchUserByDocNumber()
      : setFoundedUser(null);
  }, [docNumber]);

  return { foundedUser, docNumber, setDocNumber, searchingUser, setSearchingUser };
};

export default useSearchUserByDocNumber;
