import { Dayjs } from "dayjs";
import { IAppointment } from "../services/api/Appointment/types";
import { AppointmentStatus } from "../types/types";
import { pushItemToArrayIfItsNotIncluded } from "./miscelaneous";

export type AppointmentDates = {
  pendent: string[];
  confirmed: string[];
  canceled: string[];
  finished: string[];
};

export const getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments =
  (appointments: IAppointment[]) => {
    const auxiliarPendentAppointmentDays = [];
    const auxiliarConfirmedAppointmentDays = [];
    const auxiliarCanceledAppointmentDays = [];
    const auxiliarFinishedAppointmentDays = [];

    let iterableAppointmentDate = "";
    const highlightedDaysArray = [];

    appointments.forEach((appointment) => {
      iterableAppointmentDate = appointment.date.split("T")[0];

      highlightedDaysArray.push(iterableAppointmentDate);

      switch (appointment.status) {
        case AppointmentStatus.PENDENT:
          pushItemToArrayIfItsNotIncluded(
            auxiliarPendentAppointmentDays,
            iterableAppointmentDate
          );
          break;
        case AppointmentStatus.CONFIRMED:
          pushItemToArrayIfItsNotIncluded(
            auxiliarConfirmedAppointmentDays,
            iterableAppointmentDate
          );
          break;
        case AppointmentStatus.CANCELED:
          pushItemToArrayIfItsNotIncluded(
            auxiliarCanceledAppointmentDays,
            iterableAppointmentDate
          );
          break;
        case AppointmentStatus.FINISHED:
          pushItemToArrayIfItsNotIncluded(
            auxiliarFinishedAppointmentDays,
            iterableAppointmentDate
          );
          break;
      }
    });

    return {
      pendent: auxiliarPendentAppointmentDays,
      confirmed: auxiliarConfirmedAppointmentDays,
      canceled: auxiliarCanceledAppointmentDays,
      finished: auxiliarFinishedAppointmentDays,
    } as AppointmentDates;
  };

export const isAppointmentFromCertainDate = (
  appointment: IAppointment,
  date: Dayjs
) => appointment.date.split("T")[0] === date?.format("YYYY-MM-DD");
