import { useEffect, useState } from "react";
import { IServiceStore } from "../../services/api/ServiceStore/types";
import { fetchServiceStoresFromStore } from "../../utils/fetchEntities";
import { areStringifiedObjectsEqual } from "../../utils/miscelaneous";

const useFetchServiceStoreFromStore = (storeId: string | null) => {
  const [serviceStores, setServiceStores] = useState<IServiceStore[] | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const fetchServiceStores = () => {
    if (!storeId) return;

    setLoading(true);

    fetchServiceStoresFromStore(storeId)
      .then((response) => {
        !areStringifiedObjectsEqual(response, serviceStores) &&
          setServiceStores(response);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchServiceStores();
  }, [storeId]);

  return { serviceStores, loading, fetchServiceStores };
};

export default useFetchServiceStoreFromStore;
