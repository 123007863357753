import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Chip,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ComercialDrawer from "../../../../../components/turism/ComercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import { ExcursionStatus, FOOD_SERVICES } from "../../../../../types/types";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EditIcon from "@mui/icons-material/Edit";
import IExcursion from "../../../../../services/api/Excursion/IExcursion";
import excursionApi from "../../../../../services/api/Excursion";

import printResponseError from "../../../../../utils/printResponseError";
import {
  addAndEditExcursionSchema,
  isPriceInvalid,
} from "../excursionValidationSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import PriceInput from "../../../../../components/general/PriceInput";
import {
  CURRENT_SUBDOMAIN,
  getExcursionsFirebasePath,
} from "../../../../../utils/stringUtils";
import { IStore } from "../../../../../services/api/Store/types";
import {
  deleteImageFromFirebase,
  uploadOriginalAndPreviewImagesToFirebase,
} from "../../../../../utils/firebase/functions";
import BackPageButton from "../../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../../styles/sharedComponentStyles";
import { sharedStyles } from "../../../../../styles/shared";
import ImageGallery from "../../../../../components/comercial/ImageGallery";
import { handleDeleteExistingOriginalAndPreviewImageClick } from "../../../../petAndBeauty/Comercial/ServicesPages/sharedFunctions";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import ImageGalleryContainer from "../../../../../components/comercial/ImageGallery/ImageGalleryContainer";

const EditExcursion = () => {
  const navigate = useNavigate();

  const selectedExcursion: IExcursion =
    getLocalStorageItem("selectedExcursion");

  const storeData: IStore = getLocalStorageItem("storeData");

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addAndEditExcursionSchema) });

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [router, setRouter] = useState<string[]>(selectedExcursion.router);

  const [newImages, setNewImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImageUrls, setOriginalImageUrls] = useState<string[]>(
    selectedExcursion.imageOriginal
  );
  const [previewImageUrls, setPreviewImageUrls] = useState<string[]>(
    selectedExcursion.imagePreview
  );

  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const agentRef = useRef<HTMLInputElement>(null);
  const [price, setPrice] = useState(selectedExcursion.price / 100);
  const categoryRef = useRef<HTMLInputElement>(null);
  const restrictionRef = useRef<HTMLInputElement>(null);

  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs(selectedExcursion.startDate)
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    dayjs(selectedExcursion.endDate)
  );

  const maxVacanciesRef = useRef<HTMLInputElement>(null);
  const minVacanciesRef = useRef<HTMLInputElement>(null);
  const transportServiceRef = useRef<HTMLInputElement>(null);
  const accommodationServiceRef = useRef<HTMLInputElement>(null);
  const foodServiceRef = useRef<HTMLSelectElement>(null);
  const groupLinkRef = useRef<HTMLInputElement>(null);
  const excursionContractRef = useRef<HTMLInputElement>(null);

  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const regionRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);
  const routerRef = useRef<HTMLInputElement>(null);

  const [submittedOnce, setSubmittedOnce] = useState(false);

  const [startDateIsBeforeEndDateError, setStartDateIsBeforeEndDateError] =
    useState(false);

  const handleExcursionUpdate = async () => {
    !submittedOnce && setSubmittedOnce(true);

    if (price > 0 && price) {
      setSubmitLoading(true);

      try {
        for (let i = 0; i < selectedExcursion.imageOriginal.length; i++)
          !originalImageUrls.includes(selectedExcursion.imageOriginal[i]) &&
            (await Promise.all([
              deleteImageFromFirebase(selectedExcursion.imageOriginal[i]),
              deleteImageFromFirebase(selectedExcursion.imagePreview[i]),
            ]));

        let finalOriginalImages = originalImageUrls;
        let finalPreviewImages = previewImageUrls;

        if (newImages.length > 0) {
          const response = await uploadOriginalAndPreviewImagesToFirebase(
            newImages,
            getExcursionsFirebasePath(
              storeData.name,
              storeData.id,
              selectedExcursion.title,
              selectedExcursion.id
            )
          );

          finalOriginalImages.push(...response.originalImageUrls);
          finalPreviewImages.push(...response.previewImageUrls);
        }

        const updateExcursion: IExcursion = {
          title: titleRef.current.value,
          description: descriptionRef.current.value,
          restriction: restrictionRef.current.value,
          transportService: transportServiceRef.current.value,
          foodService: foodServiceRef.current.value,
          price: price * 100,
          agent: agentRef.current.value,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          mainCategory: categoryRef.current.value,
          router: router,
          country: countryRef.current.value,
          region: regionRef.current.value,
          state: stateRef.current.value,
          city: cityRef.current.value,
          maxVacancies: Number(maxVacanciesRef.current.value),
          minVacancies: Number(minVacanciesRef.current.value),
          groupLink: groupLinkRef.current.value,
          excursionContract: excursionContractRef.current.value,
          excursionStatus: ExcursionStatus.ACTIVE,
          listTags: [],
          imageOriginal: finalOriginalImages,
          imagePreview: finalPreviewImages,
        };

        await excursionApi.updateExcursion(
          selectedExcursion.id,
          updateExcursion
        );

        navigate("/excursions");
      } catch (error) {
        setSubmitLoading(false);
        printResponseError("Error updating excursion: ", error);
      }
      setSubmitLoading(false);
    }
  };

  const handleAddRoute = () => {
    if (routerRef.current.value !== "")
      setRouter((previousRouter) => [
        ...previousRouter,
        routerRef.current.value,
      ]);

    routerRef.current.value = "";
  };

  const handleDeleteRoute = (index: number) =>
    setRouter((previousRouter) => previousRouter.filter((_, i) => i !== index));

  useEffect(() => {
    setStartDateIsBeforeEndDateError(endDate.isBefore(startDate));
  }, [startDate, endDate]);

  const renderExcursionData = (
    <>
      <ComercialInterfaceHeader title="Dados gerais da excursão" />
      <Box sx={sharedStyles.dividedFormContainer}>
        <Box sx={{ ...sharedStyles.singleFormContainer, width: "100%" }}>
          <TextField
            label="Título *"
            size="small"
            fullWidth
            inputRef={titleRef}
            defaultValue={selectedExcursion.title}
            {...register("title")}
            error={!!errors.title}
            helperText={errors.title?.message}
          />
          <TextField
            label="Descrição"
            size="small"
            fullWidth
            inputRef={descriptionRef}
            rows={7}
            multiline
            placeholder="Descreva os detalhes da sua excursão..."
            defaultValue={selectedExcursion.description}
          />
        </Box>
        <Box sx={{ ...sharedStyles.singleFormContainer, width: "100%" }}>
          <TextField
            label="Agente *"
            size="small"
            fullWidth
            inputRef={agentRef}
            defaultValue={selectedExcursion.agent}
            {...register("agent")}
            error={!!errors.agent}
            helperText={errors.agent?.message}
          />
          <PriceInput
            label="Preço (R$)"
            size="small"
            placeholder="Ex.: R$ 500,00"
            value={price.toFixed(2)}
            onChange={(e) => setPrice(Number(e.target.value))}
            error={isPriceInvalid(submittedOnce, price)}
            autoComplete="off"
            helperText={
              isPriceInvalid(submittedOnce, price) &&
              "O preço deve ser maior que R$ 0,00"
            }
            inputRef={(input) =>
              input && isPriceInvalid(submittedOnce, price) && input.focus()
            }
          />
          <TextField
            label="Categoria *"
            size="small"
            fullWidth
            inputRef={categoryRef}
            defaultValue={selectedExcursion.mainCategory}
            {...register("mainCategory")}
            error={!!errors.mainCategory}
            helperText={errors.mainCategory?.message}
          />
          <TextField
            label="Restrição"
            size="small"
            fullWidth
            inputRef={restrictionRef}
            defaultValue={selectedExcursion.restriction}
          />
        </Box>
      </Box>
    </>
  );

  const renderExcursionDetails = (
    <>
      <ComercialInterfaceHeader title="Detalhes da excursão" />
      <Box sx={sharedStyles.singleFormContainer}>
        <Box sx={sharedStyles.dividedFormContainer}>
          <Box sx={{ ...sharedStyles.singleFormContainer, width: "100%" }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DatePicker
                label="Data de início *"
                format="DD/MM/YYYY"
                disablePast
                dayOfWeekFormatter={(e: any) => e}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                  },
                }}
                value={startDate}
                onChange={(newStartDate) => setStartDate(newStartDate)}
              />
            </LocalizationProvider>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DatePicker
                label="Data de término *"
                format="DD/MM/YYYY"
                disablePast
                dayOfWeekFormatter={(e: any) => e}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    helperText:
                      startDateIsBeforeEndDateError &&
                      "A data de término deve ser posterior a de início.",
                  },
                }}
                value={endDate}
                onChange={(newEndDate) => setEndDate(newEndDate)}
                minDate={startDate}
              />
            </LocalizationProvider>

            <Box sx={sharedStyles.dividedFormContainer}>
              <TextField
                label="Mín. de vagas *"
                size="small"
                type="number"
                fullWidth
                inputRef={minVacanciesRef}
                defaultValue={selectedExcursion.minVacancies}
                {...register("minVacancies")}
                error={!!errors.minVacancies}
                helperText={errors.minVacancies?.message}
                inputProps={{ min: 1 }}
              />
              <TextField
                label="Máx. de vagas *"
                size="small"
                type="number"
                fullWidth
                inputRef={maxVacanciesRef}
                defaultValue={selectedExcursion.maxVacancies}
                {...register("maxVacancies")}
                error={!!errors.maxVacancies}
                helperText={errors.maxVacancies?.message}
                inputProps={{ min: minVacanciesRef?.current?.value }}
              />
            </Box>
          </Box>

          <Box sx={{ ...sharedStyles.singleFormContainer, width: "100%" }}>
            <TextField
              label="Serviço de transporte *"
              size="small"
              fullWidth
              inputRef={transportServiceRef}
              defaultValue={selectedExcursion.transportService}
              {...register("transportService")}
              error={!!errors.transportService}
              helperText={errors.transportService?.message}
            />

            <TextField
              label="Serviço de hospedagem *"
              size="small"
              fullWidth
              inputRef={accommodationServiceRef}
              defaultValue={selectedExcursion.accommodationService}
              {...register("accommodationService")}
              error={!!errors.accommodationService}
              helperText={errors.accommodationService?.message}
            />
            <FormControl fullWidth size="small">
              <InputLabel>Serviço de alimentação *</InputLabel>
              <Select
                label="Serviço de alimentação"
                defaultValue={selectedExcursion.foodService}
                inputRef={foodServiceRef}
              >
                {FOOD_SERVICES.map((serviceType, index) => (
                  <MenuItem value={FOOD_SERVICES[index]} key={index}>
                    {serviceType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <TextField
          label="Link do grupo"
          size="small"
          fullWidth
          inputRef={groupLinkRef}
          defaultValue={selectedExcursion.groupLink}
        />
        <TextField
          label="Contrato da excursão"
          size="small"
          fullWidth
          inputRef={excursionContractRef}
          defaultValue={selectedExcursion.excursionContract}
        />
      </Box>
    </>
  );

  const renderExcursionGeographicData = (
    <>
      <ComercialInterfaceHeader title="Dados geográficos" />
      <Box sx={sharedStyles.singleFormContainer}>
        <Box sx={sharedStyles.storeItemContent}>
          <TextField
            label="Cidade *"
            size="small"
            fullWidth
            inputRef={cityRef}
            defaultValue={selectedExcursion.city}
            {...register("city")}
            error={!!errors.city}
            helperText={errors.city?.message}
          />
          <TextField
            label="Estado *"
            size="small"
            fullWidth
            inputRef={stateRef}
            defaultValue={selectedExcursion.state}
            {...register("state")}
            error={!!errors.state}
            helperText={errors.state?.message}
          />
        </Box>

        <Box sx={sharedStyles.storeItemContent}>
          <TextField
            label="Região *"
            size="small"
            fullWidth
            inputRef={regionRef}
            defaultValue={selectedExcursion.region}
            {...register("region")}
            error={!!errors.region}
            helperText={errors.region?.message}
          />
          <TextField
            label="País *"
            size="small"
            fullWidth
            inputRef={countryRef}
            defaultValue={selectedExcursion.country}
            {...register("country")}
            error={!!errors.country}
            helperText={errors.country?.message}
          />
        </Box>

        <Box>
          <Box display="flex">
            <TextField
              label="Adicione lugares à rota"
              size="small"
              fullWidth
              inputRef={routerRef}
            />
            <Button
              variant="contained"
              disableElevation
              sx={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
              }}
              onClick={handleAddRoute}
            >
              <AddLocationAltIcon />
            </Button>
          </Box>
          <Box margin="20px 0px">
            {router.map((route, index) => (
              <Chip
                label={route}
                onDelete={() => handleDeleteRoute(index)}
                icon={<FmdGoodIcon />}
                sx={{ margin: "5px 0px", fontSize: "18px", padding: "5px" }}
                key={index}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );

  const renderExcursionImageGallery = (
    <>
      <ImageGalleryHeader
        label="Adicionar"
        icon={<AddPhotoAlternateIcon />}
        setImages={setNewImages}
      />
      <ImageGalleryContainer>
        <ImageGallery
          images={originalImageUrls}
          setImages={setOriginalImageUrls}
          removeImageFunction={(index) =>
            handleDeleteExistingOriginalAndPreviewImageClick(
              index,
              setOriginalImageUrls,
              setPreviewImageUrls
            )
          }
          interable
        />

        <ImageGallery images={newImages} setImages={setNewImages} interable />
      </ImageGalleryContainer>
    </>
  );

  const renderEditExcursionPageContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <form
        onSubmit={onSubmit(handleExcursionUpdate)}
        style={sharedStyles.form}
      >
        {renderExcursionData}
        {renderExcursionDetails}
        {renderExcursionGeographicData}
        {renderExcursionImageGallery}

        <Box sx={sharedStyles.dividedFormContainer}>
          <PrimaryButton
            label="Confirmar edição"
            icon={<EditIcon />}
            isLoading={submitLoading}
            onClickAction={() => !submittedOnce && setSubmittedOnce(true)}
          />
          <SecondaryButton
            label="Cancelar"
            onClickAction={() => navigate("/excursions")}
            isDisabled={submitLoading}
          />
        </Box>
      </form>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderEditExcursionPageContent} />
    </Box>
  );
};

export default EditExcursion;
